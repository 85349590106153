import {
  Component, Input, forwardRef, Output, EventEmitter, OnChanges, SimpleChanges, ContentChild, TemplateRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, isObservable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InputField } from '../input-field';

export class CardOption<TModel> {
  public isEnabled: boolean = true;
  constructor(public model: TModel, public text: string, public isSelected: boolean, public imageSource?: string, public selectedImageSource?: string, public id?: string) {
  }
}

/**
 * The card options is a type of an input that takes in multiple options and displays them as card images with selectors.
 * This works similiar to a dropdown component, whereas you may use objects as your card options
 * and use a provided valuePath to use when binding the value to the formControl or ngModel. Additional to the ngModel and formControl binding, an selectedEvent will trigger anytime the selection changed.
 *
 * By default, only one option may be selected at a time (similiar to a dropdown) and selecting a different option will
 * deselect the current option. However, this functionality may be overwritten by setting the maxSelect and autoDeselect
 * input properties.
 */
@Component({
  selector: 'lc-card-options',
  templateUrl: './card-options.component.html',
  styleUrls: ['./card-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CardOptionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CardOptionsComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class CardOptionsComponent extends InputField implements OnChanges {
  @Input()
    label: string;

  @Input()
    hint: string;

  @Input()
    displayPath: string;

  @Input()
    valuePath: string;

  @Input()
    options: CardOption<any>[] | Observable<any[]>;

  @Input()
    imagePath: string;

  @Input()
    selectedImagePath: string;

  @Input()
    autoDeselect = true;

  @Input()
    maxSelect = 1;

  @Input()
    isMultiselect: boolean;

  @ContentChild('image')
    imageTemplate: TemplateRef<any>;

  @ContentChild('footer')
    footerTemplate: TemplateRef<any>;

  @Output()
  readonly blur = new EventEmitter<void>();

  @Output()
  readonly hover = new EventEmitter<CardOption<any>>();

  @Output()
  readonly selected = new EventEmitter<CardOption<any>[]>();

  options$: Observable<any[]>;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      if (isObservable(this.options)) {
        this.options$ = this.options.pipe(
          tap((options) => this.selectCurrent(options)),
          tap((options) => this.updateValue(options)),
        );
      } else {
        this.options$ = of(this.options);
        this.updateValue(this.options);
      }
    }
  }

  selectOption(options: CardOption<any>[], option: CardOption<any>) {
    // bundled items cannot be selected or deselected
    if (option.model.isBundled) { return; }
    const isSelecting = !option.isSelected;
    const selectedOptions = options.filter((o) => o.isSelected);

    if (isSelecting && selectedOptions.length >= this.maxSelect) {
      if (this.autoDeselect) {
        // Too many selected. Unselect the first option.
        // TODO: We could eventually handle it as a stack, but upon initialization, we are not sure
        // which was the initial first selected
        selectedOptions[0].isSelected = false;
      } else {
        return; // Cannot select anymore
      }
    }

    // Toggle Option Selected
    option.isSelected = !option.isSelected;
    this.selected.emit(options.filter((o) => o.isSelected));
    this.updateValue(options);
  }

  onHover(option?: CardOption<any>) {
    this.hover.emit(option);
  }

  /**
   * Updates the formControl value based on the selected options
   * @param options
   */
  private updateValue(options: CardOption<any>[]) {
    if (!options) return [];

    const selected = options.filter((option) => option.isSelected);
    if (this.maxSelect > 1 || this.isMultiselect) {
      // Multiple elements can be selected. Assuming formControl value is an array
      this.value = selected.map((option) => this.getOptionSelectedValue(option));
    } else if (selected.length === 0) {
      // Only a single element. Assuming the formControl value is not an array
      this.value = null;
    } else {
      const option = selected[0];
      this.value = this.getOptionSelectedValue(option);
    }
  }

  /**
   * Upon initialization, we need to mark the option corresponding to the value as selected
   * @param options
   * @param param1
   */
  private selectCurrent(options: CardOption<any>[]) {
    if (this.maxSelect > 1) {
      // Assuming the formControl is an array
      const selectedValues: any[] = this.value;
      options.forEach((option) => option.isSelected = selectedValues.indexOf(this.getOptionSelectedValue(option)) > -1);
    } else {
      // Assuming the formControl is a single
      options.forEach((option) => option.isSelected = this.value === this.getOptionSelectedValue(option));
    }
  }

  /**
   * Returns the proper value for the formControl from a specific card option
   * @param option
   */
  private getOptionSelectedValue(option: CardOption<any>) {
    return this.valuePath ? option.model[this.valuePath] : option.model;
  }

  parseId(id: string) {
    return (id || '').replace(/ /g, '-').replace('.', '').toLowerCase();
  }
}

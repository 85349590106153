import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogOptions, PromptDialogService } from '@lc/core';

export interface IQRDialogData {
  marketingOrderId: string;
}

@Component({
  selector: 'lc-qr-statistics-table-dialog',
  templateUrl: './qr-statistics-table-dialog.component.html',
  styleUrls: ['./qr-statistics-table-dialog.component.scss'],
  standalone: false,
})
export class QRStatisticsTableDialogComponent {
  readonly marketingOrderId: string;

  constructor(private dialogRef: MatDialogRef<QRStatisticsTableDialogComponent>, @Inject(MAT_DIALOG_DATA) data: IQRDialogData) {
    this.marketingOrderId = data?.marketingOrderId;
  }

  onClose() {
    this.dialogRef?.close();
  }

  /**
   * Opens the form as a dialog
   */
  static async open(service: PromptDialogService, data?: IQRDialogData, options?: IDialogOptions): Promise<void> {
    const defaultOptions: IDialogOptions = { width: '800px' };
    const opts: IDialogOptions = Object.assign(defaultOptions, options);
    return await service.openComponent(QRStatisticsTableDialogComponent, data, opts);
  }
}

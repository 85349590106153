import {
  Attribute, Component, ContentChild, Input, OnChanges, SimpleChanges, TemplateRef,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

export type HeaderType = 'page' | 'dialog' | 'card' | 'menu' | 'label' | 'caption';
export class HeaderConfig {
  public overflow?: 'wrap' | 'ellipsis' = 'wrap';
  public type?: HeaderType;
  constructor(
    public header: string | SafeHtml,
    public subheader: string | SafeHtml,
    public icon?: string,
    public suffixIcon?: string,
  ) {}
}

@Component({
  selector: 'lc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false,
})
export class HeaderComponent {
  @Input() config?: HeaderConfig;

  @Input() icon?: string;
  @Input() suffixIcon?: string;
  @Input() header?: string | SafeHtml;
  @Input() subheader?: string | SafeHtml;
  @Input() overflow?: 'wrap' | 'ellipsis' = 'wrap';

  @ContentChild('icon') iconTemplate?: TemplateRef<any>;
  @ContentChild('suffix') suffixTemplate?: TemplateRef<any>;

  headerClass: string;
  subheaderClass: string;

  constructor(@Attribute('type') readonly type?: HeaderType | undefined) {
    this.headerClass = this.getHeaderClass(type);
    this.subheaderClass = this.getSubheaderClass(type);
  }

  private getHeaderClass(type?: HeaderType) {
    if (type === 'dialog') { return 'mat-h3 font-weight-bold'; }
    if (type === 'card') { return 'mat-title font-weight-bold m-0 line-height-0'; }
    if (type === 'caption') { return 'mat-body-1 font-weight-500 m-0 line-height-0'; }
    return 'mat-body-2';
  }

  private getSubheaderClass(type?: HeaderType) {
    if (type === 'dialog') { return 'mat-body-1'; }

    if (type === 'card') { return 'mat-subheading-2 mat-hint m-0 line-height-0'; }
    if (type === 'caption') { return 'mat-caption m-0 line-height-0'; }
    return 'mat-caption';
  }
}

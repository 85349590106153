import {
  Directive, Input, HostBinding, OnChanges, SimpleChanges, HostListener,
} from '@angular/core';
import { PackageInstance } from '@lc/core';

// Contains any pre-existing static assets that are hosted. Used for backwards-compatibility
// as we start using package configuration to reference assets.
const FALLBACK_BADGES = {
  // Silver (LC)
  '001': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/silver_badge-small.svg',
  // Gold (LC)
  '002': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/gold_badge-small.svg',
  // Platinum (LC)
  '003': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/platinum_badge-small.svg',
  // BlueX (LC)
  '004': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/blue_badge-small.svg',
  // Bronze (LC)
  '006': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/bronze_badge-small.svg',
  // Self Service (LC)
  '007': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/silver_badge-small.svg',
  // Package A (LC) - training
  '008': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/silver_badge-small.svg',
  // Package B (LC) - training
  '009': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/platinum_badge-small.svg',
  // Blue (LC)
  '010': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/blue_badge-small.svg',
  // Temp (LC)
  '011': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/temp_badge-small.svg',
  // Gold GRA Co-Marketing (LC)
  '012': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/gra_badge-small.svg',
  // Launch (LC)
  '022': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/launch_badge-small.svg',
  // Reach (LC)
  '023': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/reach_badge-small.svg',
  // Luxe (LC)
  '024': 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/luxe_badge-small.svg',
  // A La Carte (DC)
  804: 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/a_la_carte_badge-small.svg',
  // Essentials (DC)
  900: 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/essentials_badge-small.svg',
  // Brand Boost (DC)
  901: 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/brand_boost_badge-small.svg',
  // Disruptor (DC)
  902: 'https://listing-concierge-artifacts.s3.us-west-2.amazonaws.com/package_badges/disruptor_badge-small.svg',
};

@Directive({
  selector: '[lcBadge]',
  standalone: false,
})
export class BadgeDirective implements OnChanges {
  /** The package to locate the badge for */
  @Input('lcBadge') package: PackageInstance;

  /** The badge size */
  @Input()
  @HostBinding('class.small') isSmall: boolean;

  /** Specifies whether to apply borders to this avatar */
  @Input()
  @HostBinding('class.borders') isVip: boolean;

  /** Adds the class 'badge' to the host element */
  @HostBinding('class.badge') badge: boolean = true;

  /** Host binding that sets the image source */
  @HostBinding('src') source: string;

  /** Host binding that sets the alt the elemenet */
  @HostBinding('alt') alt: string;

  /** Fallback img.src when an image is not found */
  @HostListener('error') setDefaultSource = () => {
    this.source = '/assets/images/marketing-package/broken_badge.svg';
    return false;
  };

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.package && this.package) {
      this.source = this.getBadge();
      this.alt = `${this.package?.title?.toLowerCase() || 'Package'} Badge`;
    }
  }

  private getBadge() {
    // First we will attempt to get the asset URL from `package.thumbnailUri`, which should
    // exist on orders created after LC-8786 has been deployed to production. Assets created
    // or added since LC-8786 are hosted in S3 and are uploaded by a bootstrap process.
    //
    // For older orders - where `package.thumbnailUri` does NOT exist - we will reference
    // the FALLBACK_BADGES object which preserves endpoints for pre-existing badges created
    // prior to LC-8786. These are pointing to hosted assets that appear to be remnants from
    // when we used Contentful to host many assets throughout the application.
    //
    // Finally, if `package.thumbnailUri` doesn't exist AND the package code is not included
    // in the FALLBACK_BADGES object then we return `undefined`. This will trigger the host
    // element to throw an error, which will be handled by `this.setDefaultSource()`.

    return this.package?.thumbnailUri ?? FALLBACK_BADGES[`${this.package?.code}`] ?? undefined;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { AppService, ProfileInfo } from '@lc/core';
import { ConciergeOrderFiltersForm, MultiSelectDisplayOptions } from '@lc/ui';

@Component({
  selector: 'lc-order-search-filters',
  templateUrl: './order-search-filters.component.html',
  styleUrls: ['./order-search-filters.component.scss'],
  standalone: false,
})
export class OrderSearchFiltersComponent implements OnInit {
  readonly areaDisplayOptions = new MultiSelectDisplayOptions('Area', 'Areas', true);
  readonly statusDisplayOptions = new MultiSelectDisplayOptions('Status', 'Statuses', true);
  readonly dateDisplayOptions = new MultiSelectDisplayOptions('Dates', 'Dates', true);
  readonly assignmentDisplayOptions = new MultiSelectDisplayOptions('Coordinator', 'Coordinators', true);
  readonly flagDisplayOptions = new MultiSelectDisplayOptions('Flag', 'Flagged & Unflagged', true);
  readonly isDCApp = AppService.isDesignConciergeProduct;
  readonly MAX_DATE_RANGE = 90; // days

  @Input() form: ConciergeOrderFiltersForm;
  @Input() areas: string[];

  private initialCount = 0;

  initialFlag = false;
  maxDate: Date;

  ngOnInit(): void {
    this.initialCount = this.form.selectedAreas?.length || 0;
    if (this.initialCount === 0) {
      this.initialFlag = true;
    }
  }

  updateMaxDate(startDate: Date, endDate: Date): void {
    if (endDate) {
      this.maxDate = null;
      this.form.selectedDateOption = this.form.dateOptions.find((dateOption) => dateOption.text === 'Custom');
    } else {
      const maxDate = new Date(startDate);
      maxDate.setDate(maxDate.getDate() + this.MAX_DATE_RANGE);
      this.maxDate = maxDate;
    }
  }

  onAreaClosed(event) {
    this.initialCount = this.form.selectedAreas?.length || 0;
    if (this.initialCount > 0) {
      this.initialFlag = false;
    }
    if (!event && !this.initialFlag) {
      this.resetFormControl('areas');
    }
  }

  onStatusClosed() {
    this.resetFormControl('status');
  }

  onAssignmentsClosed() {
    this.resetFormControl('assignments');
  }

  onFlagClosed() {
    this.resetFormControl('flagged');
  }

  public onAgentsSelected(agents: ProfileInfo[]) {
    const agentIds = agents.map((agent) => agent.userId);
    this.form.coordinatorIds.setValue([]);
    this.form.agentIds.setValue(agentIds);
  }

  public onCoordinatorsSelected(coordinators:ProfileInfo[]) {
    const coordinatorIds = coordinators.map((coordinator) => coordinator.userId);
    this.form.agentIds.setValue([]);
    this.form.coordinatorIds.setValue(coordinatorIds);
  }

  private resetFormControl(name: any) {
    const formControl = this.form.getControl(name);
    if (formControl && formControl.dirty) {
      formControl.markAsPristine();
    }
  }
}

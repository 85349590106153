import { Pipe, PipeTransform } from '@angular/core';
import { ServiceStatusType } from '../models/service-status.type';

@Pipe({
  name: 'photoOrderStatus',
  standalone: false,
})
export class PhotoOrderStatusPipe implements PipeTransform {
  transform(val, args) {
    switch (val) {
      case ServiceStatusType.PENDING:
      case ServiceStatusType.TODO:
        return 'To Do';
      case ServiceStatusType.CREATING:
      case ServiceStatusType.PROOFING:
        return 'In Progress';
      case ServiceStatusType.READY:
        return 'Ready';
      case ServiceStatusType.DELIVERY:
        return 'Submitting';
      case ServiceStatusType.DONE:
        return 'Done';
      default:
        return val;
    }
  }
}

import {
  Component, EventEmitter, forwardRef, Input, Output,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { StringFormControl } from '@lc/core';
import { InputField } from '../../input-field';

@Component({
  selector: 'lc-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextEditorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextEditorComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class TextEditorComponent extends InputField {
  @Input()
    label: string;

  @Input()
    placeholder = '';

  @Input()
    hint: string;

  @Input()
    suffix: string;

  @Input()
    readonly: boolean;

  @Output()
  readonly change = new EventEmitter<void>();

  @Output()
  readonly blur = new EventEmitter<void>();

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public onBlur() {
    if (this.formControl && this.formControl instanceof StringFormControl) {
      this.formControl.onLostFocus();
    }

    this.blur.emit();
    this.executeOnTouched();
  }

  public onTextChange(value) {
    this.value = value;
    this.executeOnChanged();
  }
}

import { Component, Input } from '@angular/core';
import { PackageInstance } from '@lc/core';

@Component({
  selector: 'lc-package-menu-item',
  templateUrl: './package-menu-item.component.html',
  styleUrls: ['./package-menu-item.component.scss'],
  standalone: false,
})
export class PackageMenuItemComponent {
  @Input() package: PackageInstance;
  @Input() isExpanded: boolean = true;
  @Input() isVIP: boolean;

  @Input() editLink: string | any[];
  @Input() replaceUrl: boolean;
}

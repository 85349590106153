<mat-form-field #textAreaForm [id]="idPrefix + '-form-field'" appearance="outline" floatLabel="always" [ngClass]="{ 'form-disabled' : disabled }">
   <mat-label [id]="idPrefix + '-label'" >{{label}}</mat-label>
   <textarea [id]="idPrefix + '-input'"matInput [placeholder]="placeholder" [formControl]="formControl" [errorStateMatcher]="matcher" [readonly]="readonly || disabled" [rows]="rows" (focusout)="onBlur()"></textarea>
   <mat-hint [id]="idPrefix + '-hint'">{{hint}}</mat-hint>
   <mat-error [id]="idPrefix + '-error'" *ngIf="formControl && formControl.invalid" [innerHTML]="errors"></mat-error>

     <!-- Suffix -->
  <div matSuffix *ngIf="suffix || suffixTemplate">
   <template *ngIf="suffixTemplate; else defaultSuffix" [ngTemplateOutlet]="suffixTemplate" ></template>
   <ng-template #defaultSuffix>
     <span *ngIf="suffix">{{suffix}}</span>
   </ng-template>
 </div>
</mat-form-field>

import { ValidatorFn, AbstractControl } from '@angular/forms';

export function emojiValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    // Allow the following unicode characters
    // Registered Trademark: ® (u00ae)
    // Copyright: © (u00a9)
    // Trademark: ™ (u2122)
    // Service Mark: ℠ (u2120)
    // Ellipsis: … (u2026)
    // Bullet: • (u2022)
    // Infinity: ∞ (u221e)
    // Quotes: "\'`´‘’“”"\' https://www.cl.cam.ac.uk/~mgk25/ucs/quotes.html
    const emojiPattern = /([\u221F-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
    const hasEmoji = emojiPattern.test(control.value);
    return hasEmoji ? { emojiError: 'Emojis are not allowed in the text' } : null;
  };
}

import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthGuard } from './guards/auth.guard';

import { TokenInterceptor } from './interceptors/token.interceptor';

import {
  ApiService,
  AuthenticationService,
  FormattingService,
  LoaderService,
  MarketingOrderService,
  OptionsService,
  OrderListingFlowStateService,
  PackageService,
  PdfGenerationService,
  PhotographerService,
  PhotoOrderService,
  PhotoService,
  PollingService,
  ProductGenerationService,
  ProfileService,
  QRCodeService,
  TemplateService,
  UserActivityService,
  UserAgentService,
  UserService,
  VideoGenerationService,
  VideoOrderService,
  WebsiteGenerationService,
} from './services';
import { OktaAuthenticationProvider } from './services/auth-providers/okta-authentication.provider';
import { GlobalErrorHandler } from './errors';
import { NotificationEventService } from './notifications';
import { PhotoOrderStatusPipe } from './pipes/photo-order-status.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { RemoveSpacePipe } from './pipes/removespace.pipe';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { LoaderComponent } from './loader/loader.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { SpacedPascalPipe } from './pipes/spaced-pascal.pipe';
import { FeatureGuard, TrackingHeaderGuard } from './guards';
import { PipesModule } from './pipes/pipes.module';
import {
  NotifyApiService, PdfRenderApiService, PhotoApiService, PrinterApiService,
} from './services/api.service';

@NgModule({ declarations: [
  PhotoOrderStatusPipe,
  ReversePipe,
  RemoveSpacePipe,
  LoaderComponent,
  SpacedPascalPipe,
],
exports: [
  PhotoOrderStatusPipe,
  ReversePipe,
  LoaderComponent,
  RemoveSpacePipe,
  SpacedPascalPipe,
  MatPaginatorModule,
  PipesModule,
],
imports: [CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  PipesModule],
providers: [provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AuthGuard,
        FeatureGuard,
        TrackingHeaderGuard,
        OktaAuthenticationProvider,
        AuthenticationService,
        LoaderService,
        PhotoService,
        UserService,
        PackageService,
        MarketingOrderService,
        ApiService,
        PhotoApiService,
        PdfRenderApiService,
        NotifyApiService,
        PrinterApiService,
        ProfileService,
        PhotographerService,
        NotificationEventService,
        GlobalErrorHandler,
        PhotoOrderService,
        OrderListingFlowStateService,
        QRCodeService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HeaderInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true,
        },
        {
          provide: ErrorHandler,
          useClass: GlobalErrorHandler,
        },
        PdfGenerationService,
        WebsiteGenerationService,
        ProductGenerationService,
        TemplateService,
        OptionsService,
        UserAgentService,
        UserActivityService,
        VideoOrderService,
        VideoGenerationService,
        PollingService,
        FormattingService,
      ],
    };
  }
}

import {
  Component, ContentChild, TemplateRef, Input,
} from '@angular/core';

/** TODO: Move to SpinnerComponent */
export class LoadingEvent {
  /** @deprecated. Use 'text' instead */
  get loadingText(): string {
    return this.text;
  }

  constructor(public isLoading: boolean, public text?: string) {
  }

  start(text?: string) {
    this.text = text;
    this.isLoading = true;
  }
  stop() {
    this.isLoading = false;
  }
  done() {
    this.isLoading = false;
  }

  static loading(loadingText?: string) {
    return new LoadingEvent(true, loadingText);
  }

  static get none() { return new LoadingEvent(false); }
}

@Component({
  selector: 'lc-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  standalone: false,
})
export class CardComponent {
  @Input() size: 'sm' | 'lg' = 'lg';

  @Input() header: string;
  @Input() subheader: string;
  @Input() stretchHeight: boolean = true;
  @Input() stretchWidth: boolean = false;
  @Input() contentClass: string;
  @Input() loading?: LoadingEvent;

  @ContentChild('image') imageTemplate: TemplateRef<any>;
  @ContentChild('header') headerTemplate: TemplateRef<any>;
  @ContentChild('content') contentTemplate: TemplateRef<any>;
  @ContentChild('actions') actionsTemplate: TemplateRef<any>;
  @ContentChild('radiobutton') radioButtonTemplate: TemplateRef<any>;
}

// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';

// Internal Component
import { MultiselectComponent } from './multiselect.component';

const angularModules = [
  CommonModule,
  ReactiveFormsModule,
  MatSelectModule,
  MatIconModule,
];

@NgModule({
  declarations: [MultiselectComponent],
  exports: [MultiselectComponent],
  imports: [...angularModules],
})
export class MultiselectModule { }

import { Component, Input } from '@angular/core';

@Component({
  selector: 'lc-company-menu-item',
  templateUrl: './company-menu-item.component.html',
  styleUrls: ['./company-menu-item.component.scss'],
  standalone: false,
})
export class CompanyMenuItemComponent {
  @Input() companyName: string;
  @Input() isExpanded: boolean = true;
}

<ng-container *ngIf="form">
  <div class="pt-1 pb-1">
    <span class="mat-subheading-2 font-weight-bold">Search Orders</span>
  </div>
  <!-- Address and Area search container-->
  <div lcClass.lt-md="fx-column" lcClass.gt-sm="fx-row fx-gap-sm">
    <!-- Address Search Filter-->
    <lc-textbox class="fx-1-1-0" label="Search by Address" placeholder="Search by Address" [formControl]="form.search" idPrefix="search">
    </lc-textbox>

    <!-- Area Filter-->
    <lc-multiselect class="fx-1-1-0" label="Area" idPrefix="area" (openedChange)="onAreaClosed($event)"
      [displayOptions]="areaDisplayOptions" [options]="areas" [(ngModel)]="form.selectedAreas">
      <ng-template #optionTpl let-item="item">
        <div><span class="status-text option-title">{{item}}</span></div>
      </ng-template>
    </lc-multiselect>
  </div>

  <div lcClass.lt-md="fx-column" lcClass.gt-sm="fx-row fx-gap-sm">
    <!-- Status Filter-->
    <lc-multiselect class="fx-1-1-0" idPrefix="status" label="Filter by:" (openedChange)="onStatusClosed()"
      [displayOptions]="statusDisplayOptions" [options]="form.statusOptions" [(ngModel)]="form.selectedStatuses"
      displayPath="label">
      <ng-template #optionTpl let-item="item">
        <div>
          <span class="ui-icon ui-icon-status-bullet" [style]="{'background-color': item.color}"></span>
          <span class="status-text option-title">{{item.label}}</span></div>
      </ng-template>
    </lc-multiselect>

    <!-- Date Filter-->
    <lc-multiselect
      #dateSelect
      class="fx-1-1-0"
      label="Dates"
      idPrefix="date"
      dropdownClass="date-filter"
      multiple="false"
      idPath="text"
      displayPath="display"
      [displayOptions]="dateDisplayOptions"
      [options]="form.dateOptions"
      [(ngModel)]="form.selectedDateOption"
      (openedChange)="onDateToggled($event)"
    >

      <ng-template #optionTpl let-item="item">
        <div>
          <span class="option-title">{{item.text}}</span>
        </div>

        <lc-datepicker
          #range
          *ngIf="item.text === 'Custom'"
          (click)="form.selectedDateOption = item; $event.stopPropagation()"
          [enableRangeSelection]="true"
          (dateChange)="item.startDate = $event.start; item.endDate = $event.end; dateSelect.updateDisplay();"
          [defaultStartDate]="item.startDate"
          [defaultEndDate]="item.endDate"
        >
        </lc-datepicker>
      </ng-template>
    </lc-multiselect>
  </div>

  <div lcClass.lt-md="fx-column" lcClass.gt-sm="fx-row fx-gap-sm">
    <div class="fx-row fx-gap-sm fx-fill">
      <lc-dropdown *ngIf="form.userFilters === 'both'" class="users fx-1-1-0" label="Search Users By:" [options]="form.userFilterOptions" displayPath="viewValue" valuePath="value" [(ngModel)]="form.selectedUserFilterOption"></lc-dropdown>

      <lc-agent-search *ngIf="form.selectedUserFilterOption === 'agent'" class="fx-1-1-0" label="Agent:" placeholder="Search by Agent name" [formControl]="form.agentSearch"
        [selectedIds]="form.selectedAgentIds" [searchBy]= "form.selectedUserFilterOption" (selectedChanged)="onAgentsSelected($event)" >
      </lc-agent-search>

      <lc-agent-search *ngIf="form.selectedUserFilterOption === 'lcc'" class="fx-1-1-0" label="Coordinator:" placeholder="Search by Coordinator name"
        [formControl]="form.coordinatorSearch" [selectedIds]="form.selectedCoordinatedIds"
        (selectedChanged)="onCoordinatorsSelected($event)" [searchBy]= "form.selectedUserFilterOption">
      </lc-agent-search>
    </div>

    <lc-multiselect *ngIf="form.showAssignment" class="fx-1-1-0" idPrefix="assignment" label="Assigned to:"
      (openedChange)="onAssignmentsClosed()" [displayOptions]="assignmentDisplayOptions"
      [options]="form.assignmentOptions" [formControl]="form.getControl('assignments')">
    </lc-multiselect>
  </div>

</ng-container>

import {
  Directive, ElementRef, Input, OnChanges,
} from '@angular/core';

@Directive({
  selector: '[lcNoBreakHyphen]',
  standalone: false,
})
export class NoBreakHyphenDirective implements OnChanges {
  @Input() lcNoBreakHyphen = '';

  constructor(private el: ElementRef) { }

  ngOnChanges(): void {
    // We take the string passed in with the [lcNoBreakHyphen] binding on an element in a template
    // and replace any occurence of '-' with '&#8209;' which is the unicode representation of the
    // non-breaking hyphen character. We set the resulting string to the host's `innerHTML` value
    // so that the unicode is properly parsed.
    if (typeof this.lcNoBreakHyphen === 'string') {
      this.el.nativeElement.innerHTML = this.lcNoBreakHyphen.replace(/-/g, '&#8209;');
    } else {
      console.error(`[NoBreakHyphenDirective] - Recieved ${this.lcNoBreakHyphen} - Cannot assign a non-string to element.innerHTML.`);
    }
  }
}

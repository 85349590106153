import { Pipe, PipeTransform } from '@angular/core';
import { MarketingOrderStatusType } from '@lc/core';

@Pipe({
  name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {
  /**
   * Transforms the status from the UI to a friendly display
   */
  transform(val: string) {
    if (!val) { return val; }

    switch (val) {
      case MarketingOrderStatusType.SUBMITTED: return 'New';
      case MarketingOrderStatusType.PENDING: return 'Ready';
      case MarketingOrderStatusType.PROCESSING: return 'In Progress';
      case MarketingOrderStatusType.COMPLETED: return 'Review';
      case MarketingOrderStatusType.CLOSED: return 'Closed';
      default: return this.capitalize(val.toLowerCase().replace(/_/g, ' '));
    }
  }

  private capitalize = (str: any) => {
    if (typeof str === 'string') {
      return str.replace(/^\w/, (c) => c.toUpperCase());
    }
    return '';
  };
}

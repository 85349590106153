import {
  Component, Input, OnDestroy, HostBinding, Optional, Self, Output, EventEmitter, ElementRef, OnInit, OnChanges,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Observable, Subject } from 'rxjs';

/**
 * Change the default quill behavior so that left aligned text
 * is recorded with class="ql-align-left" in the output HTML.
 *
 * We do this before the ngx-quill module contents are imported.
 *
 * NOTE: This only works when 'left' is defined as a value in the
 *       options for alignment in the toolbar and that option is not
 *       marked as 'selected'
 */
import Quill from 'quill';

import { QuillModules, QuillToolbarConfig } from 'ngx-quill';
import { TextSummaryService, FeatureFlags, AppService, LaunchDarklyService } from '@lc/core';
import { InputField } from '../../input-field';
import { RichTextSanitizerService } from './rich-text-sanitizer.service';
import { getNativeElement } from '../../../../../../products/src/lib/shared/components/util/util';

const Align = Quill.import('attributors/class/align') as any;
const Icons = Quill.import('ui/icons') as any;
Icons.align.left = Icons.align['']; // set icon for 'left' option, otherwise it's replaced with 'undefined' text
Align.whitelist = ['left', 'center', 'right']; // add explicit 'left' option
Quill.register(Align, true);

/**
 * We allow either rich-text (html) or plain text (text)
 * We allow either rich-text (html) or plain text (text)
 */
enum Format {
  html = 'html',
  text = 'text',
}

/**
 * If we allow rich text these are the valid toolbar options
 *
 * See: https://quilljs.com/docs/formats/
 */
const ALLOWED_RICH_TEXT_QUILL_FORMATS = [
  'bold',
  'italic',
  'underline',
  'list',
  'align',
  'link',
];

/**
 * If we only allow plain text, or disallow rich text options then
 * there are no valid options for quill to accept.
 */
const ALLOWED_PLAIN_TEXT_QUILL_FORMATS = [];

/**
 * Wraps the ngx-quill integration of QuillJS so we can edit rich text inline in the UI.
 *
 * See: https://www.npmjs.com/package/ngx-quill ,section QuillEditorComponent > Config for all of the
 * configuration options that can be set on quill-editor.
 */
@Component({
  selector: 'lc-editor-control',
  templateUrl: './editor-control.component.html',
  styleUrls: ['./editor-control.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: EditorControlComponent },
    RichTextSanitizerService,
  ],
  standalone: false,
})
export class EditorControlComponent extends InputField implements MatFormFieldControl<{}>, OnInit, OnChanges, OnDestroy {
  aiFeatureIsEnabled$: Observable<boolean>;
  private static nextId = 0;
  private _placeholder: string;

  readonly modules: QuillModules;
  readonly toolbars: QuillToolbarConfig;
  readonly flags = FeatureFlags;

  editorValue: string = '';

  public readonly stateChanges = new Subject<void>();

  @HostBinding()
  // eslint-disable-next-line no-plusplus
  public id = `lc-editor-${EditorControlComponent.nextId++}`;

  @HostBinding('attr.aria-describedby')
  public describedBy = '';

  @Input()
  public get placeholder() {
    return this._placeholder;
  }

  public set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input() statistics: any;
  @Input() isHeader: boolean;
  @Input() isContact: boolean;
  @Input() minimumHeight: string;
  @Input() setBorder: boolean;
  @Input() format: Format = Format.html;
  @Input() toolbarEnabled: boolean = true;
  @Input() richTextEnabled: boolean = true;
  @Input() showUndo: boolean = false;
  @Input() undoDisabled: boolean = true;

  @Output() readonly selectionChange = new EventEmitter();
  @Output() readonly undoAiGeneration = new EventEmitter();

  hasSummarized: boolean = false;
  summarizedValue: string = '';
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  required: boolean;
  disabled: boolean;
  isAgentApp: boolean = AppService.isAgentApp;

  @Input()
    errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  isHTMLOverflow: boolean = false;
  maxPercentage: number;
  spinnerDiameter: number;

  quillFormat: Format;
  quillFormats: string[] = ALLOWED_RICH_TEXT_QUILL_FORMATS;
  quillToolbarEnabled: boolean = true;
  quillRichTextEnabled: boolean = true;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    sanitizer: DomSanitizer,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private summaryService: TextSummaryService,
    private richTextSanitizerService: RichTextSanitizerService,
    private launchDarklyService: LaunchDarklyService,
  ) {
    super(sanitizer);
    this.aiFeatureIsEnabled$ = this.launchDarklyService.getFeature$(FeatureFlags.AI_PROPERTY_DESCRIPTION, false);
    this.change.subscribe(() => { this.stateChanges.next(); }, () => { });
    fm.monitor(this.getNativeElement(), true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    }, () => { });

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      // See https://material.angular.io/guide/creating-a-custom-form-field-control#-code-ngcontrol-code-
      this.ngControl.valueAccessor = this;
    }

    this.toolbars = [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: ['left', 'center', 'right'] }],
      ['link'],
    ];

    this.modules = { toolbar: this.toolbars };
  }

  ngOnInit() {
    this.maxPercentage = this.isHeader ? 95 : 100;
    this.editorValue = this.ngControl.value;
    this.spinnerDiameter = 20;

    // Override the variables we pass to quill editor
    // in case they were passed as input to this component
    this.quillFormat = this.format;
    this.quillToolbarEnabled = this.toolbarEnabled;
    this.quillRichTextEnabled = this.richTextEnabled;

    // enforce options when the format is plain text
    if (this.quillFormat === Format.text) {
      this.quillRichTextEnabled = false;
      this.quillToolbarEnabled = false;
      this.quillFormats = ALLOWED_PLAIN_TEXT_QUILL_FORMATS;
    } else if (!this.quillRichTextEnabled) {
      this.quillFormats = ALLOWED_PLAIN_TEXT_QUILL_FORMATS;
    }
  }

  ngOnChanges(): void {
    this.isHTMLOverflow = this.statistics && this.statistics.percentage >= this.maxPercentage;
  }

  setFocus(editor) {
    editor.focus();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.getNativeElement());

    if (!this.isContact) {
      this.selectionChange.emit(this.editorValue);
    }
  }

  private getNativeElement() {
    return getNativeElement(this.elRef, 'EditorControlComponent');
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element)?.tagName?.toLowerCase() !== 'input') {
      this.getNativeElement()?.querySelector('input')?.focus();
    }
  }

  async onSummarizeClicked() {
    this.hasSummarized = true;

    if (this.statistics.percentage >= 100) {
      const summaryResult = await this.summaryService?.summarizeText(this.editorValue, this.statistics.maximum)?.toPromise();

      if (summaryResult?.summary?.extractive?.length > 0) {
        const summary = `<p>${summaryResult?.summary?.extractive?.join(' ')}</p>`;
        this.summarizedValue = summary;
        this.value = summary;
        this.editorValue = summary;
      }
    }
  }

  public onBlur($event) {
    this.editorValue = $event?.editor?.root?.innerHTML;
  }

  public onTextChange($event) {
    // Sanitize the contents of the text editor to remove unsupported
    // tags (not the same as Angular DOM sanitizer), this is to remove stuff
    // that the pdf render pipeline cannot handle.
    const result = this.richTextSanitizerService?.sanitize($event.content);

    // If the sanitization changed contents, handle it
    // See the comments below as to why we do this.
    if (result?.sanitized) {
      // update the editor contents with the sanitized content
      // and pass the change on to the rest of angular
      //
      // This will result in this method: onTextChange being called a second
      // time but sanitization will have no effect on already sanitized text
      // so this block will not execute the second time.
      $event?.editor?.setContents(result.content, 'user');
    } else {
      this.editorValue = $event?.html;

      if ($event?.text?.toLowerCase().trim() !== this.summarizedValue?.toLowerCase().trim()) {
        this.hasSummarized = false;
      }
    }
  }

  onClickedOutside($event) {
    const path = $event?.path || $event?.composedPath();
    const checkIfBtn = path[0];
    const isBtn = checkIfBtn?.outerHTML?.includes('mat-button-wrapper');

    /* -- About below code --
      Limitation due to Quill Editor's onblur event design
      this check is needed as contact block may pop rules modal again and again!
    */
    const isDoneOrOkBtn = path[1]?.outerHTML?.includes('Done') || path[1]?.outerHTML?.includes('Page');

    if (!isBtn || (isBtn && isDoneOrOkBtn)) {
      this.selectionChange?.emit(this.editorValue);
      this.executeOnChanged();
    }
  }

  onUndo() {
    this.undoAiGeneration.emit();
  }
}

// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

// Internal Component
import { FormButtonsComponent } from './form-buttons.component';

const angularModules = [CommonModule, MatButtonModule];
const internalComponents = [];
const externalComponents = [FormButtonsComponent];
const externalModules = [
  MatButtonModule, ReactiveFormsModule, FormsModule,
];

@NgModule({
  declarations: [...internalComponents, ...externalComponents],
  exports: [
    ...externalComponents,
    ...externalModules,
  ],
  imports: [...angularModules],
})
export class FormButtonsModule { }

// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

// Third Party Modules
import { NgxFileDropModule } from 'ngx-file-drop';

// LC Modules
import { CoreModule } from '@lc/core';
import { DialogsModule } from '../dialogs/dialogs.module';

import { ImageInputComponent } from './image-input/image-input.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';

import { PhotoCropperDirective } from './directives/photo-cropper.directive';

// Dialog Components
import { ImageCropperDialog } from './dialogs/image-cropper/image-cropper.dialog';

const angularModules = [
  CommonModule, FormsModule, ReactiveFormsModule, MatSliderModule, MatStepperModule,
  MatFormFieldModule, MatIconModule, MatInputModule, MatMenuModule, MatButtonModule, MatButtonToggleModule,
];
const thirdPartyModules = [NgxFileDropModule];
const lcModules = [CoreModule, DialogsModule];
const exportComponents = [ImageInputComponent, FileUploadComponent, ImageCropperComponent];
const directives = [PhotoCropperDirective];
const dialogs = [ImageCropperDialog];

@NgModule({
  declarations: [...exportComponents, ...directives, ...dialogs],
  exports: [...exportComponents, ...directives],
  imports: [
    ...angularModules,
    ...thirdPartyModules,
    ...lcModules,
  ],
})
export class ImagesModule { }

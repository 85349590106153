import {
  AfterViewInit, Component, EventEmitter, OnInit, ViewChild,
} from '@angular/core';
import {
  GetOrder, MarketingOrder, Notification, NotificationService, NotificationType, UserService,
} from '@lc/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  distinctUntilChanged, filter, map, tap,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

export class NotificationViewModel {
  icon: string;
  title: string;
  text: string;
  path: string;
  when: string;
  preNavigationAction: Function;

  constructor(
    public readonly notification: Notification,
    private store: Store<any>,
  ) {
    this.title = notification.subject;
    this.text = notification.text;
    this.path = this.getRouterLink();
    this.icon = this.getIcon();
    this.when = this.getWhenDisplay();
    this.preNavigationAction = this.getNavigationAction();
  }

  getRouterLink() {
    let productTitle;
    // TODO: these routes should be stored in a route service or something
    switch (this.notification.notificationType) {
      case NotificationType.ASSIGNED_TO_ORDER:
        return `manage-order/${this.notification.marketingOrderId}`;
      case NotificationType.MLS_ID_ADDED:
        return `manage-order/${this.notification.marketingOrderId}/listing-details`;
      case NotificationType.LISTING_STATUS_SOLD:
        return `manage-order/${this.notification.marketingOrderId}/listing-details`;
      case NotificationType.REQUESTED_MARKETING_COPY:
        return `manage-order/${this.notification.marketingOrderId}/property-description`;
      case NotificationType.REQUESTED_PHOTOS:
      case NotificationType.PRO_PHOTOS_RECEIVED:
        return `manage-order/${this.notification.marketingOrderId}/media/photos`;
      case NotificationType.ASSIGNED_TO_TEAM:
        return 'my-profile/team';
      case NotificationType.ORDER_CONFIRMATION:
      case NotificationType.PRODUCT_SHIPPED:
        return `manage-order/${this.notification.marketingOrderId}`;
      case NotificationType.PRODUCT_READY_FOR_PROOFING:
      case NotificationType.LCC_PROOF_REJECTED_APP_NOTIFICATION:
      case NotificationType.LCC_PROOF_APPROVED_APP_NOTIFICATION:
      case NotificationType.VENDOR_REJECTED_ORDER:
      case NotificationType.PRODUCT_READY_FOR_REVIEW:
        productTitle = this.notification.otherData && this.notification.otherData.product
          ? this.notification.otherData.product.title
          : null;

        if (productTitle) {
          return `manage-order/${this.notification.marketingOrderId}/products-services/${productTitle.toLowerCase().replace(/ /g, '-')}/preview/description`;
        }

        return `manage-order/${this.notification.marketingOrderId}/products-services`;
      case NotificationType.MISSING_PROPERTY_PHOTOS:
        return `manage-order/${this.notification.marketingOrderId}/media/photos`;
      case NotificationType.MISSING_PROPERTY_DESCRIPTION:
        return `manage-order/${this.notification.marketingOrderId}/property-description`;
      case NotificationType.YOUTUBE_AD_READY_FOR_REVIEW:
        return `manage-order/${this.notification.marketingOrderId}/products-services/you-tube-advertising/viewer`;
    }
  }

  getIcon() {
    // TODO: Should we use a pipe for these?
    switch (this.notification.notificationType) {
      case NotificationType.ASSIGNED_TO_ORDER:
        return 'home';
      case NotificationType.REQUESTED_MARKETING_COPY:
        return 'description';
      case NotificationType.REQUESTED_PHOTOS:
      case NotificationType.PRO_PHOTOS_RECEIVED:
        return 'filter';
      case NotificationType.ASSIGNED_TO_TEAM:
        return 'groups';
      case NotificationType.PRODUCT_SHIPPED:
        return 'send';
      default: return 'home';
    }
  }

  // TODO: Move to a pipe?
  getWhenDisplay() {
    if (!this.notification.audit) { return '12 Hours Ago'; } // TODO: This is a fake placeholder until we have a createdAt
    const diff = Math.floor(new Date().getTime() - this.notification.audit.createdAt.getTime());
    let hours = 1000 * 60 * 60;
    const day = hours * 24;

    hours = Math.floor(diff / hours);
    const days = Math.floor(diff / day);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);
    if (hours < 24) {
      return `${hours} Hours Ago`;
    } if (days < 31) {
      return `${days} Days Ago`;
    } if (months < 12) {
      return `${months} Months Ago`;
    }
    return `${years} years Ago`;
  }

  getNavigationAction(): Function {
    switch (this.notification.notificationType) {
      case NotificationType.PRO_PHOTOS_RECEIVED:
      case NotificationType.LCC_PROOF_REJECTED_APP_NOTIFICATION:
      case NotificationType.LCC_PROOF_APPROVED_APP_NOTIFICATION:
      case NotificationType.PRODUCT_READY_FOR_REVIEW:
      case NotificationType.PRODUCT_READY_FOR_PROOFING:
      case NotificationType.MISSING_PROPERTY_DESCRIPTION:
        return (marketingOrderId) => {
          this.store.dispatch(GetOrder({ payload: new MarketingOrder({ _id: marketingOrderId }), useCached: false }));
        };
      default:
        // do nothing
        return () => {};
    }
  }

  get isRead(): boolean {
    return this.notification.isRead;
  }
}

export class ViewModel {
  notificationViewModels: NotificationViewModel[];
  unread : Notification[];

  constructor(
    notifications: Notification[],
    store: Store<any>,
    private notificationService: NotificationService,
    private router: Router,
    private userService: UserService,
  ) {
    this.unread = (notifications || []).filter((n) => !n.isRead);
  }

  get count(): number {
    return this.unread?.length;
  }

  get badge(): string {
    return this.count > 9 ? '9+' : `${this.count}`;
  }

  markOneAsRead(notificationViewModel: NotificationViewModel) {
    notificationViewModel.preNavigationAction(notificationViewModel.notification.marketingOrderId);
    this.router.navigate([notificationViewModel.path]);
    this.notificationService.markAsRead(notificationViewModel.notification);
  }

  markAllAsRead() {
    const notifications = this.unread.map((notification) => notification?._id);
    this.notificationService.markAllAsRead(notifications);
    while (this.notificationViewModels?.length) {
      this.notificationViewModels?.pop();
    }
  }

  markAllNotificationsAsRead() {
    const userId = this.userService.getUserId();
    this.notificationService.markAllNotificationsAsRead(userId);
    while (this.notificationViewModels?.length) {
      this.notificationViewModels?.pop();
    }
  }
}

@Component({
  selector: 'lc-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],
  standalone: false,
})
export class NotificationsMenuComponent implements OnInit, AfterViewInit {
  private readonly _newNotificationCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  public readonly newNotificationCount$ = this._newNotificationCount$.asObservable();

  viewModel$: Observable<ViewModel>;
  elementNode = 10;
  readonly virtualScrollEvent = new EventEmitter();
  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScroll: CdkVirtualScrollViewport;
  viewModel: ViewModel;
  notesViewModels: NotificationViewModel[] = [];
  withReverseOrderNotes: any[] = [];

  constructor(
    private store: Store<any>,
    private service: NotificationService,
    private router: Router,
    private userService: UserService,
  ) {
    this.viewModel$ = this.service.get().pipe(
      map((notifications) => new ViewModel(notifications, this.store, this.service, this.router, this.userService)),
      tap((vm: ViewModel) => this.viewModel = vm),
    );
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    this.virtualScrollEvent.pipe(distinctUntilChanged(), filter((renderEnd) => renderEnd === this.cdkVirtualScroll.getDataLength())).subscribe(
      (next) => {
        if (next === this.withReverseOrderNotes?.length) {
          return;
        }
        const getnoteElement = this.getItemCountNode(next, this.withReverseOrderNotes, this.elementNode);
        const getNotesElementNode = getnoteElement.map((notification) => new NotificationViewModel(notification, this.store));
        this.notesViewModels = [...this.notesViewModels, ...getNotesElementNode];
      },
      (error) => console.error('notification-menu component', error),
    );
  }

  getItemCountNode(count = 30, unreadItemsNode, elementNode) {
    return unreadItemsNode.slice(count, count + elementNode);
  }

  onNotesScroll() {
    this.virtualScrollEvent.emit(this.cdkVirtualScroll.getRenderedRange().end);
  }

  /**
   * Handles the click event of a notification and navigates to the proper route. Marks the notification as read
   */
  onNotificationClicked(notificationViewModel: NotificationViewModel, viewModel: ViewModel, event: MouseEvent) {
    const { target } = event;
    if ((target as any)?.href) {
      // Click occured on an embedded anchor tag. Don't mark as read
      return;
    }
    viewModel.markOneAsRead(notificationViewModel);
  }

  getAllNotes(notes) {
    console.log('Notifications clicked by the user');
    this.withReverseOrderNotes = (notes.unread || []);
    const getnoteElement = this.withReverseOrderNotes.slice(0, this.elementNode);
    this.notesViewModels = getnoteElement.map((notification) => new NotificationViewModel(notification, this.store));
  }
}

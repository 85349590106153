import { Component, Input } from '@angular/core';

@Component({
  selector: 'lc-area-menu-item',
  templateUrl: './area-menu-item.component.html',
  styleUrls: ['./area-menu-item.component.scss'],
  standalone: false,
})
export class AreaMenuItemComponent {
  @Input() areaName: string;
  @Input() isExpanded: boolean = true;
}

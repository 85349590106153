import {
  Component, Injectable, EventEmitter, forwardRef, Input, Output, OnDestroy, OnInit,
} from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomValidator } from '@lc/core';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Mask } from '../input-masks';
import { InputField } from '../input-field';

const digitDateStringRegex = /^[0-9]{6}$/;
@Injectable()
class MyDateAdapter extends NativeDateAdapter {
  /**
   * Look at the string entered by the user and if we find a 6-digit string we add in slash '/' characters to get a
   * date stirng in MM/DD/YY format and parse that.
   *
   * This lets a user enter a date quickly like '121120' and not end up with a bogus date.
   *
   * @param value
   */
  parse(value: any): Date {
    const valueToParse = CustomValidator.stringValueOf(value);
    if (valueToParse.match(digitDateStringRegex)) {
      // Attempt to add slashes in before testing if it is valid
      const dateStringWithSlashes = `${valueToParse.substring(0, 2)}/${valueToParse.substring(2, 4)}/${valueToParse.substring(4)}`;
      return super.parse(dateStringWithSlashes);
    }

    // Parse the date string using the native adapter
    return super.parse(valueToParse);
  }
}

@Component({
  selector: 'lc-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    // give our own data adapter to handle fast keyboard entry using only digits.
    { provide: DateAdapter, useClass: MyDateAdapter },
  ],
  standalone: false,
})
export class DatepickerComponent extends InputField implements OnInit, OnDestroy {
  @Input()
    label: string;

  @Input()
    placeholder = '';

  @Input()
    popoverPlacement: 'default' | 'top' = 'default';

  @Input()
    inputMask: Mask;

  @Input()
    maxDate: Date;

  @Input()
    minDate: Date;

  @Input()
    defaultStartDate: Date;

  @Input()
    defaultEndDate: Date;

  @Input()
    hint: string;

  @Input()
    readonly: string;

  @Input()
    enableRangeSelection = false;

  private dateSubscription: Subscription = new Subscription();
  startDateControl = new FormControl();
  endDateControl = new FormControl();

  @Output()
    dateChange = new EventEmitter<{ start: Date, end: Date }>();

  @Output()
  readonly blur = new EventEmitter<any>();

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  ngOnInit(): void {
    if (this.enableRangeSelection) {
      this.startDateControl.setValue(this.defaultStartDate);
      this.endDateControl.setValue(this.defaultEndDate);

      const startDateSub = this.startDateControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe((startDate) => {
          this.dateChange.emit({ start: startDate, end: this.endDateControl.value });
        });

      const endDateSub = this.endDateControl.valueChanges
        .pipe(debounceTime(100))
        .subscribe((endDate) => {
          this.dateChange.emit({ start: this.startDateControl.value, end: endDate });
        });

      this.dateSubscription.add(startDateSub);
      this.dateSubscription.add(endDateSub);
    }
  }

  ngOnDestroy(): void {
    this.dateSubscription.unsubscribe();
  }
}

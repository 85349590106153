// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { MatAutocompleteModule } from '@angular/material/autocomplete';

// Internal Component
import { AutocompleteComponent } from './autocomplete.component';

const angularModules = [
  CommonModule,
  ReactiveFormsModule,
  MatInputModule,
  MatAutocompleteModule,
];
const internalComponents = [];
const externalComponents = [AutocompleteComponent];

const externalModules = [
  MatInputModule, ReactiveFormsModule, MatAutocompleteModule,
];

@NgModule({
  declarations: [...internalComponents, ...externalComponents],
  exports: [externalComponents, ...externalModules],
  imports: [...angularModules],
})
export class AutoCompleteModule { }

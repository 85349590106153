import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isBrand',
  standalone: false,
})
export class IsBrandPipe implements PipeTransform {
  transform(brandCode: string, allowedBrands: string[]): boolean {
    return allowedBrands.includes(brandCode);
  }
}

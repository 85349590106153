import { CommonModule } from '@angular/common';
import {
  Component, ContentChild, EventEmitter, forwardRef, Input, Output, TemplateRef,
} from '@angular/core';

import {
  FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseForm, LCFormArray } from '@lc/core';
import { HoveredDirective, InputField } from '@lc/ui';

@Component({
  selector: 'lc-form-array',
  templateUrl: './form-array.component.html',
  styleUrls: ['./form-array.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormArrayComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormArrayComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatListModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    HoveredDirective,
  ],
})
export class FormArrayComponent extends InputField {
  @Input() label: string;
  @Input() hint?: string;
  @Input() form: LCFormArray<any, any>;
  @Input() canEdit?: boolean = true;
  @Input() canAdd?: boolean = true;

  @ContentChild('edit') editTemplate: TemplateRef<any>;
  @ContentChild('view') viewTemplate: TemplateRef<any>;
  @ContentChild('input') inputTemplate: TemplateRef<any>;

  @Output() readonly edit = new EventEmitter<BaseForm>();
  @Output() readonly deleted = new EventEmitter<BaseForm>();

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  onEdit(form: BaseForm) {
    this.edit.emit(form);
    form.isEdit = true;
  }

  onCancel(optionForm: BaseForm): void {
    if (optionForm.isNew) {
      this.form?.deleteControl(optionForm);
    } else {
      optionForm.reset();
      optionForm.isEdit = false;
    }
    this.form.updateValueAndValidity();
  }

  onDone(form: BaseForm) {
    if (form.invalid) { return form.markAllAsDirty(); }

    // Reset these fields so if the user clicks edit again,
    // it will start from the current state
    form.setOriginalValue(form.value);
    form.isEdit = false;
    this.form.updateValueAndValidity();
  }

  onDelete(form: BaseForm) {
    this.form.deleteControl(form);
    this.deleted.emit(form);
  }
}

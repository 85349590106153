<lc-chips [idPrefix]="idPrefix" class="agent-search" [label]="label" [options]="memberSearch$ | async" [formControl]="formControl"
  [max]="max" [emptyPlaceholder]="emptyPlaceholder" [placeholder]="placeholder"
  [inputFormControl]="searchControl" (selectedChanged)="updateAgents($event)"
  [class.has-selected]="selected?.length > 0" removeByUser = true [excludeByUserId] = "excludeByUserId"
  [noResultsDisplay]="noResultDisplayText">

  <!--Tag Template -->
  <ng-template #tag let-member="item">
    {{member.firstName}} {{member.lastName}}
  </ng-template>

  <!-- Template to display the member -->
  <ng-template #option let-member="item">
    <div class="row align-items-center m-0">
      <lc-avatar [photoUrl]="member?.profilePhotoUrl || member?.photoUrl" [size]="38" class="agent-avatar"></lc-avatar>
      <div class="ml-2 d-inline-block" style="line-height: 1em">
        {{member.firstName}} {{member.lastName}}<br>
        {{member.officeName}}
      </div>
    </div>
  </ng-template>

</lc-chips>

import { Component } from '@angular/core';

@Component({
  selector: 'lc-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.scss'],
  standalone: false,
})
export class PrivacyPolicyPageComponent {
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ThumbnailTypes = exports.ExifData = exports.OrientationTypes = exports.ExifParserFactory = void 0;
var ExifParserFactory_1 = require("./lib/ExifParserFactory");
Object.defineProperty(exports, "ExifParserFactory", {
  enumerable: true,
  get: function () {
    return ExifParserFactory_1.ExifParserFactory;
  }
});
var ExifData_1 = require("./lib/ExifData");
Object.defineProperty(exports, "OrientationTypes", {
  enumerable: true,
  get: function () {
    return ExifData_1.OrientationTypes;
  }
});
Object.defineProperty(exports, "ExifData", {
  enumerable: true,
  get: function () {
    return ExifData_1.ExifData;
  }
});
Object.defineProperty(exports, "ThumbnailTypes", {
  enumerable: true,
  get: function () {
    return ExifData_1.ThumbnailTypes;
  }
});

// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

// Third Party Modules
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

// Internal Component
import { PhoneNumberComponent } from './phone-number.component';

const angularModules = [
  CommonModule,
  ReactiveFormsModule,
  MatInputModule,
];
const thirdPartyModules = [NgxMaskDirective];
const internalComponents = [];
const externalComponents = [PhoneNumberComponent];

const externalModules = [
  MatInputModule, ReactiveFormsModule,
];

@NgModule({
  declarations: [...internalComponents, ...externalComponents],
  exports: [...externalComponents, ...externalModules],
  imports: [...angularModules, ...thirdPartyModules],
  providers: [provideNgxMask()],
})
export class PhoneNumberModule { }

import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'lc-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  standalone: false,
})
export class PageHeaderComponent {
  @Input() header: string;
  @Input() subheader: string;
  @Input() caption: string;

  @HostBinding('class.bg-light') readonly background = true;
}

import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

export class Tab {
  constructor(public label: string, public path: string, public id: string, public isVisible$: Observable<boolean> = of(true)) { }
}

@Component({
  selector: 'lc-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.scss'],
  standalone: false,
})
export class NavigationTabsComponent {
  @Input()
    tabs: Tab[];

  @Input()
    activeId: string;
}

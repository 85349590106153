import {
  Component, Input, OnChanges, SimpleChanges,
} from '@angular/core';
import {
  AddressForm, AddressInformationForm, BaseForm, DeliveryAddressForm, STATES_BY_ABBREVIATION, SmartyStreetService,
} from '@lc/core';
import { Observable } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'lc-address-details-form',
  templateUrl: './address-details-form.component.html',
  styleUrls: ['./address-details-form.component.scss'],
  standalone: false,
})
export class AddressDetailsFormComponent implements OnChanges {
  @Input() formGroup: AddressForm | DeliveryAddressForm | AddressInformationForm;

  addressOptions$: Observable<any[]>;
  constructor(public smartyStreetService?: SmartyStreetService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formGroup && this.formGroup) {
      this.addressOptions$ = (this.formGroup instanceof AddressForm
        ? this.formGroup.getControl('streetAddress1')
        : (this.formGroup.getControl('address') as BaseForm).getControl('streetAddress1'))
        .valueChanges
        .pipe(
          debounceTime(200),
          switchMap(async (data) => {
            if (this.smartyStreetService.isSearchable(data)) {
              console.log(`SmartStreety API -> ${data} Requested`);
              return await this.smartyStreetService.getAddressSuggestions(data);
            }

            return [];
          }),
          catchError((error) => {
            console.log(`AddressDetailsFormComponent  Smart street error = ${error} `);
            return [];
          }),
        );
    }
  }

  /**
   * Updates entire form with returned response from smartyStreet API
   * @param event
   */
  updateAddressFields(event) {
    const address = {
      city: event.city ? event.city : '',
      postalCode: event.postalCode ? event.postalCode : '',
      state: event.state ? event.state : '',
      streetAddress1: event.streetAddress1 ? event.streetAddress1 : '',
      streetAddress2: event.streetAddress2 ? event.streetAddress2 : '',
    };

    this.formGroup instanceof AddressForm
      ? this.formGroup.setValue(address)
      : this.formGroup.getControl('address').setValue(address);
  }

  getControl(controlName: string) {
    return this.formGroup instanceof AddressForm
      ? this.formGroup.get(controlName)
      : this.formGroup.getControl('address').get(controlName);
  }

  getStates() {
    return STATES_BY_ABBREVIATION;
  }
}

// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';

// Internal Component
import { CheckboxComponent } from './checkbox.component';

const angularModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatInputModule,
  MatIconModule,
  MatCheckboxModule,
];
const externalComponents = [CheckboxComponent];

const externalModules = [
  FormsModule, ReactiveFormsModule, MatCheckboxModule,
];

@NgModule({
  declarations: [
    ...externalComponents,
  ],
  exports: [
    ...externalComponents,
    ...externalModules,
  ],
  imports: [...angularModules],
})
export class CheckboxModule { }

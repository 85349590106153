import {
  Component, Inject, QueryList, ViewChild, ViewChildren,
} from '@angular/core';
import { CroppedImage, PhotoType, LoaderService } from '@lc/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ImageCropperComponent } from '../../image-cropper/image-cropper.component';
import { PhotoCropSettings } from '../../directives';

export class ViewModel {
  readonly url: string;
  readonly settings: PhotoCropSettings;
  readonly hasViewed: FormControl;

  constructor(readonly request: CropRequest) {
    this.settings = request.settings;
    const hasViewed = this.settings.findSelectedDimensions() != null;
    this.hasViewed = new FormControl(hasViewed, [Validators.requiredTrue]);

    // Need to append a timestamp to the url to force S3 to reload
    const currentTimeInMilliseconds = Date.now();
    this.url = `${request.imageUrl}?ts=${currentTimeInMilliseconds}`;
  }
}

export class CropRequest {
  constructor(readonly imageUrl: string, readonly display: string, readonly settings: PhotoCropSettings) {

  }
}

export class ImageCropperDialogData {
  readonly requests: CropRequest[];
  // Instantiate with one or many requests
  constructor(requests: CropRequest | CropRequest[], readonly saveInS3: boolean = true, readonly photoType: PhotoType = PhotoType.LISTING_PHOTO) {
    this.requests = requests instanceof CropRequest ? [requests] : requests;
    this.requests.map((request) => request.settings.allowZoomOut = photoType === PhotoType.PROFILE_PHOTO);
  }
}

export class ImageCropperDialogResponse {
  constructor(readonly croppedPhotos: CroppedImage[]) {
  }
}

@Component({
  selector: 'lc-image-cropper-dialog',
  templateUrl: './image-cropper.dialog.html',
  styleUrls: ['./image-cropper.dialog.scss'],
  standalone: false,
})
export class ImageCropperDialog {
  readonly title: string;
  readonly viewModels: ViewModel[];
  imageReadyToCrop: Boolean = false;

  /** Access to the MatStepper displayed on the UI */
  @ViewChild(MatStepper) stepper: MatStepper;

  /** Access to all of the ImageCropperComponents on the UI */
  @ViewChildren(ImageCropperComponent) croppers: QueryList<ImageCropperComponent>;

  /** Determines if we are on the last photo cropper step */
  get isLastStep() { return this.stepper && this.stepper.selectedIndex >= (this.stepper.steps.length - 1); }

  private readonly _nextText$: BehaviorSubject<string>;
  readonly nextText$: Observable<string>;

  private readonly _close = new Subject<ImageCropperDialogResponse>();
  public readonly close = this._close.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: ImageCropperDialogData,
    private loaderService: LoaderService,
  ) {
    // Initialize the viewModels for the view
    this.viewModels = data?.requests?.map((request) => new ViewModel(request)) || [];

    // If there are multiple photos, set the title to a generic text. Otherwise, use the first photo's display
    this.title = this.viewModels.length > 1 ? 'Crop Photos' : this.viewModels[0].request.display;
    this._nextText$ = new BehaviorSubject(this.viewModels.length > 1 ? 'Next' : 'Apply');
    this.nextText$ = this._nextText$.asObservable();
  }

  /**
   * Wait for image to load completely before allowing
   * a user to click 'apply'.
   * @param viewModel
   * @param value
   */
  imageLoaded(viewModel, value) {
    // set hasViewed property for valid form.
    viewModel.hasViewed.setValue(value);

    // set conditional property to disable/enable button.
    this.imageReadyToCrop = value;
  }

  /** Handles the next event on the UI and submits the dialog if on the last page */
  async onNext() {
    if (this.isLastStep) {
      // If we are on the final step, submit the dialog
      return await this.submit();
    }

    // Otherwise, navigate to the next page
    this.stepper.next();
  }

  /** Handles the cancel event on the UI and closes the dialog */
  onCancel() {
    this._close.next(null);
  }

  /** Occurs when the stepper changes steps */
  onStepChanged(event: StepperSelectionEvent) {
    const isOnLastStep = event.selectedIndex === (this.viewModels.length - 1);
    this._nextText$.next(isOnLastStep ? 'Apply' : 'Next');
  }

  /** Crops all of the images and closes the dialogs with the proper dialog response */
  private async submit() {
    this.loaderService.show();
    const promises: Promise<CroppedImage>[] = this.croppers.toArray().map(async (cropper) => await cropper.onCrop(this.data, this.data.photoType));
    const crops = await Promise.all(promises);
    this.loaderService.hide();
    this._close.next(new ImageCropperDialogResponse(crops));
  }
}

import {
  Component, Input, forwardRef, Output, EventEmitter,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { InputField } from '../input-field';

@Component({
  selector: 'lc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class CheckboxComponent extends InputField {
  @Input()
    checked: boolean;

  @Input()
    label: string;

  @Input()
    hint: string;

  @Input()
    readonly: boolean;

  @Input() showErrors: boolean;

  @Output()
  readonly blur = new EventEmitter<void>();

  @Output() readonly toggled = new EventEmitter<boolean>();

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public onBlur() {
    this.blur.emit();
    this.executeOnTouched();
  }

  public onChanged(event: MatCheckboxChange) {
    this.toggled.emit(event?.checked);
  }
}

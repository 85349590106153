// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

// Internal Component
import { DropdownComponent } from './dropdown.component';

const angularModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatInputModule,
  MatSelectModule,
];
const externalComponents = [DropdownComponent];

const externalModules = [
  ReactiveFormsModule, FormsModule,
  MatInputModule, ReactiveFormsModule,
];

@NgModule({
  declarations: [...externalComponents],
  exports: [...externalComponents, ...externalModules],
  imports: [...angularModules],
})
export class DropdownModule { }

import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import {
  Component, forwardRef, Input, EventEmitter, Output, OnChanges, SimpleChanges, ContentChild, TemplateRef, ViewChild, ElementRef, AfterViewInit,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, AbstractControl,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { InputField } from '../input-field';
import { getNativeElement } from '../../../../../products/src/lib/shared/components/util/util';

/**
 * The model used to display the options
 */
export interface IChipOption<TModel = any> {
  /** The label for the card */
  label: string;

  /** The value to set the form control to when selected */
  value: TModel;

  /** Whatever value this was originally mapped to */
  model?: any;

  isSelected?: boolean;
}

@Component({
  selector: 'lc-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ChipsComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class ChipsComponent extends InputField implements OnChanges, AfterViewInit {
  separatorKeysCodes: number[] = [];

  @ContentChild('option')
    optionTemplate: TemplateRef<any>;

  @ContentChild('tag')
    tagTemplate: TemplateRef<any>;

  @ContentChild('prefix')
    prefixTemplate: TemplateRef<any>;

  @Input() label: string;

  @Input() placeholder = '';

  @Input() hint: string;

  @Input() emptyPlaceholder = '';

  @Input() prefix: string;

  @Input() allowCustomInput = false;

  @Input() displayPath: string;

  @Input() noResultsDisplay: string;

  @Input() valuePath: string;

  @Input() options: any[];

  @Input() inputFormControl: FormControl = new FormControl();

  @Output() readonly selectedChanged = new EventEmitter<any[]>();

  @Input() max: number;

  @Input() removeByUser: boolean;

  @Input() excludeByUserId: Boolean;

  @Input() size: 'xs' | 'sm' | 'md' = 'md';

  @ViewChild('chipList') chipList;
  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  canAdd: boolean;
  hasSelection: boolean;
  chipOptions: IChipOption<any>[] = [];

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.options || changes.selectedValues) {
      this.updateOptions();
    }

    if (changes.allowCustomInput) {
      this.separatorKeysCodes = this.allowCustomInput ? [COMMA, ENTER, TAB] : [];
    }

    if (changes.inputFormControl && this.inputFormControl.validator?.length) {
      this.inputFormControl.valueChanges.subscribe(() => {
        this.inputFormControl.markAsTouched();
        this.setErrors(this.inputFormControl);
      }, (error) => { throw new Error(error); });
    }
  }

  ngAfterViewInit() {
    if (this.formControl) {
      this.formControl.valueChanges.subscribe(
        () => {
          this.chipList.errorState = !this.formControl.valid;
          this.setErrors(this.formControl);
        },
        (error) => { throw new Error(error); },
      );
      this.formControl.statusChanges.subscribe(() => {
        this.chipList.errorState = !this.formControl.valid;
        this.setErrors(this.formControl);
      }, (error) => { throw new Error(error); });
    }
  }

  protected async afterFormInitialized() {
    this.updateSelection(false);
    super.afterFormInitialized();
  }
  /** Occurs when the user types in the input field */
  onUserInput($event: any) {
    this.inputFormControl.setValue($event.target.value);
    this.inputFormControl.markAsDirty();
    this.inputFormControl.markAsTouched();
  }

  onItemRemoved(option: IChipOption) {
    option.isSelected = false;
    this.updateSelection();
  }

  onSelected(event: MatAutocompleteSelectedEvent) {
    const option: IChipOption = event.option.value;
    if (!option.isSelected) {
      option.isSelected = true;
      this.updateSelection();
    }
    this.clearInput();
  }

  private updateSelection(emitChanges: boolean = true) {
    const values = (this.chipOptions || [])
      .filter((option) => option.isSelected)
      .map((option) => option.value);
    if (emitChanges) {
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity();
      this.formControl.setValue(values);
      this.selectedChanged.emit(values);
    }
    this.canAdd = !this.max || values.length < this.max;
    this.hasSelection = !!values.length;
  }

  onInputAdd(event: MatChipInputEvent) {
    // Do not allow custom add.
    // They must click on an option in the autocomplete
    if (!this.allowCustomInput) {
      return;
    }

    const value = `${(event.value || '')}`.trim();

    // No input has been set. Just tabbing out
    if (value.length === 0) {
      return;
    }

    const isAlreadySelected = this.chipOptions.find((opt) => opt.isSelected && opt.value === value);
    if (isAlreadySelected) {
      this.inputFormControl.setErrors({ custom: 'Value already exists' }, { emitEvent: true });
    }
    if (!this.inputFormControl.valid) {
      this.inputFormControl.markAsDirty();
      this.inputFormControl.markAsTouched();
      this.formControl.setErrors(this.inputFormControl.errors);
      this.formControl.markAsDirty();
      return;
    }

    // Since we are allowing custom input, we assume the formcontrol is a string[]
    this.chipOptions.push({
      label: value,
      value,
      isSelected: true,
    });

    this.updateSelection();
    this.inputFormControl.reset();
    event.input.value = '';
  }

  private updateOptions() {
    const value = this.value || [];
    const selectedValues: any[] = value instanceof Array ? value : [value];
    this.chipOptions = (this.options || []).map((option) => {
      const display = (this.displayPath ? option[this.displayPath] : option) || '';
      const value = this.valuePath ? option[this.valuePath] : option;

      const isSelected = (selectedValues || []).includes(value);
      return {
        label: display,
        value,
        model: option,
        isSelected,
      };
    });

    // Add the selected values that are not in the options provided
    selectedValues
      .filter((value) => !this.chipOptions.some((opt) => opt.value === value))
      .forEach((value) => this.chipOptions.push({
        label: this.displayPath && typeof value === 'object'
          ? value[this.displayPath] ?? value
          : value,
        value,
        isSelected: true,
        model: value,
      }));
  }

  /**
   * Update the selected values with the formControl/ngModel value changes.
   */
  protected executeOnChanged() {
    this.updateOptions();
    // Call through the base method
    super.executeOnChanged();
  }

  protected setErrors(formControl: AbstractControl) {
    this.errors = this.getDisplayErrors(formControl) || this.getDisplayErrors(this.inputFormControl);
    this.hasErrors = this.errors !== '';
    if (this.chipList) {
      this.chipList.errorState = this.hasErrors;
    }
  }

  private clearInput() {
    getNativeElement(this.chipInput, 'ChipsComponent').value = '';
    this.inputFormControl.setValue('');
  }
}

import { datadogLogs } from '@datadog/browser-logs';
import StackTrace from 'stacktrace-js';
import { datadogRum } from '@datadog/browser-rum';

export class DataDogLogger {
  static initialize(clientToken) {
    try {
      datadogLogs.init({
        clientToken,
        datacenter: 'us',
        /**
         * forwardErrorsToLogs = true will automatically handle the following:
         * 1.) Logs Network errors to DataDog (We are replacing these in the error.interceptor.ts)
         * 2.) Logs unhandled exceptions to DataDog (We are handling these in global-error-handler --> error.service)
         * 3.) Logs console.error to DataDog (Which we are already handling down below in overrideConsoleLogging() )
         *
         * Leave this set to true until we are confident our own logging is sufficient for debugging/diagnosing purposes
         */
        forwardErrorsToLogs: true,
        sampleRate: 100,
      });

      datadogLogs.addLoggerGlobalContext('env', this.getEnvironment());
      datadogLogs.addLoggerGlobalContext('host', location.hostname);
      datadogLogs.addLoggerGlobalContext('service', this.getAppName());

      this.overrideConsoleLogging();
    } catch (e) {
      console.error(e);
    }
  }

  static getEnvironment() {
    const hostNameTokens = location.hostname.split('.');
    if (location.hostname.includes('listing-concierge.com')) {
      return 'prod';
    }
    return hostNameTokens[1];
  }

  static getAppName() {
    const hostNameTokens = location.hostname.split('.');
    const app = hostNameTokens[0] ? `${hostNameTokens[0].split('-')[1]}-web-app` : 'unknown';
    return app;
  }

  static initializeDDRealUserMonitoring(clientToken: string) {
    try {
      datadogRum.init({
        applicationId: '19c504b0-b30e-4996-b397-cd202a4827be',
        clientToken,
        site: 'datadoghq.com',
        service: this.getAppName(),
        // Specify a version number to identify the deployed version of your application in Datadog
        version: '1.0.0',
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
        trackUserInteractions: true,
        trackFrustrations: true,
        enableExperimentalFeatures: ['feature_flags'],
        env: this.getEnvironment(),
      });

      if (this.getAppName() === 'agent-web-app') {
        datadogRum.startSessionReplayRecording();
      }
    } catch (error) {
      console.error('datadog - failed to initialize RUM: ', error);
    }
  }

  private static overrideConsoleLogging() {
    // Code based on: http://tobyho.com/2012/07/27/taking-over-console-log/
    function intercept(method) {
      const original = console[method];
      console[method] = function () {
        try {
          // do sneaky stuff
          if (original.apply) {
            // Do this for normal browsers
            original.apply(console, arguments);
          } else {
            // Do this for IE
            const message = Array.prototype.slice.apply(arguments).join(' ');
            original(message);
          }
          const msgOptions = Array.prototype.slice.apply(arguments).slice(1);
          let stackStr = '';
          StackTrace.getSync().forEach((sf) => stackStr = `${stackStr}${sf.source}\n`);
          const ddMsg = DataDogLogger.getDDLogString(arguments[0], msgOptions);
          datadogLogs.logger[method](ddMsg, { stacktrace: stackStr });
        } catch (e) {
          original.apply(console, e);
          datadogLogs.logger.error(e.toString(), { error: JSON.stringify(e) });
        }
      };
    }

    const methods = ['log', 'warn', 'error', 'debug'];
    for (let i = 0; i < methods.length; i++) intercept(methods[i]);
  }

  private static getString(message) {
    if (message === null || typeof message === 'undefined') return '';
    let msgStr = message;
    if (typeof message === 'object') {
      msgStr = JSON.stringify(message);
    } else if (typeof message === 'symbol') {
      msgStr = message.toString();
    } else if (
      typeof message === 'bigint'
      || typeof message === 'number'
      || typeof message === 'boolean'
    ) {
      msgStr = `${message}`;
    }
    return msgStr;
  }

  private static getDDLogString(message?: any, optionalParams?: any[]) {
    if (message === null || typeof message === 'undefined') return message;
    try {
      let msgStr = DataDogLogger.getString(message);
      if (optionalParams && optionalParams.length > 0) {
        optionalParams.forEach((msg) => {
          msgStr = `${msgStr}\n${DataDogLogger.getString(msg)}`;
        });
      }
      return msgStr;
    } catch (e) {
      return message;
    }
  }
}

<mat-checkbox
  [id]="idPrefix + '-form-field'"
  [checked]="checked"
  [formControl]="formControl"
  [class.no-margin]="!(hint || showErrors)"
  [class.mat-checkbox-disabled]="readonly"
  [disableRipple]="readonly"
  (click)="readonly ? $event.preventDefault() : null"
  color="primary"
  (change)="onChanged($event)"
>
<div class="fx-column">
  <ng-content></ng-content>
    <mat-label [id]="idPrefix + '-label'">{{label}}</mat-label>
    <span style="font-size:85%"
      [ngClass]="(showErrors && errors) ? 'mat-error' : 'mat-hint'"
      [innerHTML]="(showErrors ? errors : null) || hint"
    ></span>
  </div>
</mat-checkbox>
<!-- 
<div *ngIf="hint || showErrors" style="margin-left:40px; font-size: 75%; margin-top: -5px;">

</div> -->

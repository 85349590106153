import {
  NgModule, InjectionToken, Type, APP_BOOTSTRAP_LISTENER, Inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule, EffectSources } from '@ngrx/effects';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ORDERSTATE } from './workflow/workflow.actions';
import { workflowReducer } from './workflow/workflow.reducer';
import { orderReducer } from './order/order.reducer';
import { SELECTEDORDER } from './order/order.actions';
import { WorkflowEffects } from './workflow/workflow.effects';
import { OrderEffects, OrderStateEffects } from './order/order.effects';
import { debugReducer } from './debug.reducer';
import { LOGGEDINUSER } from './user/user.actions';
import { userReducer } from './user/user.reducer';

import { SELECTEDPROFILE } from './profile/profile.actions';
import { profileReducer } from './profile/profile.reducer';
import { ProfileEffects } from './profile/profile.effects';
import { SELECTEDTEAM } from './team/team.actions';
import { teamReducer } from './team/team.reducer';
import { TeamEffects } from './team/team.effects';
import { NOTIFICATIONS } from './notifications/notification.actions';
import { notificationReducer } from './notifications/notification.reducer';
import { NotificationEffects } from './notifications/notification.effects';
import { keysReducer } from './keys/key.reducer';
import { KEYS } from './keys/key.actions';
import { PHOTOGRAPHY_AGENCIES } from './photography-agency/photography-agency.actions';
import { photographyAgencyReducer } from './photography-agency/photography-agency.reducer';
import { PhotographyAgencyEffects } from './photography-agency/photography-agency.effects';
import { USERSESSION } from './session/session.actions';
import { sessionReducer } from './session/session.reducer';

export const metaReducers: MetaReducer<any>[] = [/* debugReducer */];
export const BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');

export function bootstrapEffects(effectTypes: Type<any>[], sources: EffectSources) {
  return () => {
    effectTypes.forEach((effectType) => sources.addEffects(effectType));
  };
}

export function createInstances(...instances: any[]) {
  return instances;
}

export function provideBootstrapEffects(effectTypes: Type<any>[]) {
  return [
    effectTypes,
    { provide: BOOTSTRAP_EFFECTS, deps: effectTypes, useFactory: createInstances },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: bootstrapEffects,
      deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources],
    },
  ];
}

const effects = [
  WorkflowEffects, OrderEffects, OrderStateEffects,
  ProfileEffects, TeamEffects,
  NotificationEffects, PhotographyAgencyEffects,
];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers,
        runtimeChecks: {
          // These runtime checks have been added and need to be disabled as we didn not code according to these
          // guidlines: https://ngrx.io/guide/store/configuration/runtime-checks
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      },
    ),
    EffectsModule.forRoot([]),
    StoreModule.forFeature(ORDERSTATE, workflowReducer),
    StoreModule.forFeature(SELECTEDORDER, orderReducer),
    StoreModule.forFeature(LOGGEDINUSER, userReducer),
    StoreModule.forFeature(USERSESSION, sessionReducer),
    StoreModule.forFeature(NOTIFICATIONS, notificationReducer),
    StoreModule.forFeature(SELECTEDPROFILE, profileReducer),
    StoreModule.forFeature(SELECTEDTEAM, teamReducer),
    StoreModule.forFeature(KEYS, keysReducer),
    StoreModule.forFeature(PHOTOGRAPHY_AGENCIES, photographyAgencyReducer),
    EffectsModule.forFeature([]),
    StoreDevtoolsModule.instrument({ maxAge: 10, connectInZone: true }),
  ],
  declarations: [],
  providers: [
    // NGRX Bug Workaround: NGRX Effects loads before APP_INITIALIZER loads causing issues with appSettings
    // https://github.com/ngrx/platform/issues/174#issuecomment-373853594
    provideBootstrapEffects(effects),
  ],
})
export class StateModule { }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe(
  {
    name: 'removeSpace',
    standalone: false,
  },
)
export class RemoveSpacePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/\s/g, '-').toLowerCase();
  }
}

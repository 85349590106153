<nav mat-tab-nav-bar class="lc-navigation-bar mb-3" [tabPanel]="tabPanel">
  <!-- Display each tab item as a link-->
  <ng-container *ngFor="let tab of tabs">
    <a mat-tab-link *ngIf="tab.isVisible$ | async" [id]="tab.id" [routerLink]="tab.path" [active]="tab.id == activeId">{{tab.label}}</a>
  </ng-container>
</nav>

<!-- The router outlet displays the child component -->
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>


<mat-form-field appearance="outline" [floatLabel]="'always'">

  <mat-label [id]="idPrefix + '-label'">{{label}}</mat-label>
  <mat-select #select matInput [id]="idPrefix + '-input'" [attr.aria-owns]="aria"
              [formControl]="formControl" [placeholder]="placeholder" [errorStateMatcher]="matcher"
              (selectionChange)="onSelectionChanged($event)">
    <mat-option *ngIf="emptyOptionDisplay" [value]="emptyOptionValue"
                [disabled]="emptyOptionDisabled" [class.disabled-option]="emptyOptionDisabled">
      {{ emptyOptionDisplay }}
    </mat-option>

    <mat-select-trigger *ngIf="showTrigger">
      {{selected?.label || placeholder }}
    </mat-select-trigger>

    <mat-option *ngFor="let option of dropdownOptions" [value]="option.value" [id]="option.id"
                [disabled]="disabledPath && option?.value[disabledPath]"
                [ngClass]="{ 'line-height-unset': optionTemplate }">


      <ng-container *ngIf="select.panelOpen; else collapsed">
        <ng-container *ngIf="optionTemplate || displayTemplate; else defaultOption"
                      [ngTemplateOutlet]="optionTemplate || displayTemplate"
                      [ngTemplateOutletContext]="{item: option.model}">
        </ng-container>
      </ng-container>

      <ng-template #collapsed>
        <ng-container *ngIf="displayTemplate; else defaultOption"
                      [ngTemplateOutlet]="displayTemplate"
                      [ngTemplateOutletContext]="{item: option.model}">
        </ng-container>
      </ng-template>

      <ng-template #defaultOption>
        <span class="option-title">{{option.label}} </span>
      </ng-template>
    </mat-option>

  </mat-select>
  <mat-hint [id]="idPrefix + '-hint'">{{hint}}</mat-hint>
  <mat-error [id]="idPrefix + '-error'" *ngIf="formControl && formControl.invalid"
             [innerHTML]="errors"></mat-error>

  <!-- Fake input used to handle autofill changes. Needs to hide behind the screen. display: none does not work -->
  <input matInput class="hidden-autofill-input" tabindex="-1" (change)="onAutoFill($event)"
         [formControl]="formControl">

</mat-form-field>
"use strict";

(self.webpackChunk_abt_desk_rsc = self.webpackChunk_abt_desk_rsc || []).push([["rsc-speed-dial"], {
  1251: function (e, t, o) {
    o.r(t), o.d(t, {
      SpeedDialModule: function () {
        return O;
      }
    });
    var i = o(625),
      n = o(7636),
      a = o(8583),
      s = o(558),
      r = o(4854),
      l = o(898),
      c = o(4693);
    let d = (() => {
      class e extends i.Xj {
        validateContainerElement() {
          document.querySelector(".cdk-overlay-container.rsc-overlay") || (this._createContainer(), this.getContainerElement().classList.add("rsc-overlay"));
        }
      }
      return e.ɵfac = function () {
        let t;
        return function (o) {
          return (t || (t = c.n5z(e)))(o || e);
        };
      }(), e.ɵprov = c.Yz7({
        token: e,
        factory: e.ɵfac,
        providedIn: "root"
      }), e;
    })();
    var f = o(3815),
      p = o(5319),
      g = o(4395),
      m = o(7238);
    const u = [(0, m.X$)("fabToggler", [(0, m.SB)("inactive", (0, m.oB)({
      display: "block"
    })), (0, m.SB)("active", (0, m.oB)({
      display: "none"
    })), (0, m.eR)("active => inactive", (0, m.jt)("300ms"))]), (0, m.X$)("fabTogglerClose", [(0, m.SB)("inactive", (0, m.oB)({
      transform: "scale(0)",
      display: "block"
    })), (0, m.SB)("active", (0, m.oB)({
      transform: "scale(1)",
      display: "block"
    })), (0, m.eR)("* <=> *", (0, m.jt)("300ms ease-in-out"))]), (0, m.X$)("speedDialStagger", [(0, m.eR)("* => *", [(0, m.IO)(":enter", (0, m.oB)({
      opacity: 0
    }), {
      optional: !0
    }), (0, m.IO)(":enter", (0, m.EY)("40ms", [(0, m.jt)("300ms cubic-bezier(0.4, 0.0, 0.2, 1)", (0, m.F4)([(0, m.oB)({
      opacity: 0,
      transform: "translateY(10px)"
    }), (0, m.oB)({
      opacity: 1,
      transform: "translateY(0)"
    })]))]), {
      optional: !0
    }), (0, m.IO)(":leave", (0, m.EY)("-40ms", [(0, m.jt)("300ms cubic-bezier(0.4, 0.0, 0.2, 1)", (0, m.F4)([(0, m.oB)({
      opacity: 1,
      transform: "translateY(0)"
    }), (0, m.oB)({
      opacity: 0,
      transform: "translateY(10px)"
    })]))]), {
      optional: !0
    })])])];
    var b = o(4124),
      h = o(8140),
      v = o(1095);
    const y = ["overlayTemplate"],
      x = ["overrideIconTemplate"],
      T = function (e) {
        return {
          value: e
        };
      };
    function k(e, t) {
      if (1 & e && (c.ynx(0), c._UZ(1, "img", 11), c.BQk()), 2 & e) {
        const e = c.oxw(2);
        c.xp6(1), c.Q6J("src", e.speedDialIcon, c.LSH)("@fabToggler", c.VKq(2, T, e.fabTogglerState));
      }
    }
    function I(e, t) {
      if (1 & e && c._UZ(0, "i"), 2 & e) {
        const e = c.oxw(2);
        c.Gre("fab-open-icon ", e.fabIcon, ""), c.Q6J("@fabToggler", c.VKq(4, T, e.fabTogglerState));
      }
    }
    function S(e, t) {
      if (1 & e && (c.TgZ(0, "span", 2), c.YNc(1, k, 2, 4, "ng-container", 9), c.YNc(2, I, 1, 6, "ng-template", null, 10, c.W1O), c.qZA()), 2 & e) {
        const e = c.MAs(3),
          t = c.oxw();
        c.Q6J("@fabToggler", c.VKq(3, T, t.fabTogglerState)), c.xp6(1), c.Q6J("ngIf", null === t.fabIcon)("ngIfElse", e);
      }
    }
    function C(e, t) {
      if (1 & e) {
        const e = c.EpF();
        c.ynx(0), c.TgZ(1, "a", 15), c.NdJ("click", function () {
          c.CHM(e);
          const t = c.oxw().$implicit;
          return c.oxw().optionClicked(t);
        }), c.TgZ(2, "button", 16), c._UZ(3, "i"), c.qZA(), c.qZA(), c.BQk();
      }
      if (2 & e) {
        const e = c.oxw().$implicit;
        c.xp6(1), c.Q6J("href", e.url, c.LSH)("target", null != e.target && e.target.length ? e.target : "_blank"), c.xp6(2), c.Tol(e.icon);
      }
    }
    function Z(e, t) {
      if (1 & e) {
        const e = c.EpF();
        c.TgZ(0, "button", 17), c.NdJ("click", function () {
          c.CHM(e);
          const t = c.oxw().$implicit;
          return c.oxw().optionClicked(t);
        }), c._UZ(1, "i"), c.qZA();
      }
      if (2 & e) {
        const e = c.oxw().$implicit;
        c.xp6(1), c.Tol(e.icon);
      }
    }
    function w(e, t) {
      if (1 & e && (c.TgZ(0, "div", 12), c.TgZ(1, "span", 13), c._uU(2), c.qZA(), c.YNc(3, C, 4, 4, "ng-container", 9), c.YNc(4, Z, 2, 2, "ng-template", null, 14, c.W1O), c.qZA()), 2 & e) {
        const e = t.$implicit,
          o = c.MAs(5);
        c.xp6(2), c.Oqu(e.name), c.xp6(1), c.Q6J("ngIf", null == e.url ? null : e.url.length)("ngIfElse", o);
      }
    }
    function q(e, t) {}
    const D = ["*"];
    let A = (() => {
        class e {
          constructor(e, t, o, i, n) {
            this.utilityService = e, this.overlay = t, this.overlayContainer = o, this.themeService = i, this.cdr = n, this.subscription = new p.w(), this.speedDialOptions = [], this.options = [], this.fabTogglerState = "inactive", this.showDefaultIcon = !1, this.speedDialIcon = "assets/rsc-icons/fab_icon.svg", this.fabIcon = null, this.brandKey = null, this.color = "accent", this.dataHandler();
          }
          ngAfterContentInit() {
            var e, t, o;
            try {
              this.showDefaultIcon = 0 === (null === (o = null === (t = null === (e = this.overrideIconTemplate) || void 0 === e ? void 0 : e.nativeElement) || void 0 === t ? void 0 : t.innerHTML) || void 0 === o ? void 0 : o.trim().length);
            } catch (i) {}
            this.themeService.initializeTheme("rsc-speed-dial", this.brandKey);
          }
          dataHandler() {
            this.utilityService.getData$().pipe((0, g.b)(1)).subscribe(e => {
              if (e.speedDialOptions && Array.isArray(e.speedDialOptions)) {
                const t = f.cloneDeep(e);
                this.speedDialOptions = t.speedDialOptions.sort((e, t) => e.weight < t.weight ? 1 : -1), this.hideItems();
              }
            });
          }
          showItems() {
            this.fabTogglerState = "active", this.options = Array.from(this.speedDialOptions), this.openComponentOverlay();
            try {
              document.querySelector(".cdk-overlay-container.rsc-overlay").style.setProperty("z-index", "1000", "important");
            } catch (e) {}
          }
          hideItems() {
            this.fabTogglerState = "inactive", this.options = [];
            const e = document.querySelector(".speed-dial-backdrop");
            e && (e.classList.remove("cdk-overlay-backdrop-showing"), this.cdr.detectChanges());
          }
          onToggleFab() {
            "inactive" === this.fabTogglerState ? this.showItems() : this.hideItems();
          }
          openComponentOverlay() {
            this.overlayContainer.validateContainerElement();
            const e = new i.X_({
              backdropClass: "speed-dial-backdrop"
            });
            e.hasBackdrop = !0, this.overlayRef = this.overlay.create(e), this.subscription.add(this.overlayRef.backdropClick().subscribe(() => {
              this.hideItems();
            })), this.overlayRef.attach(this.overlayTemplate);
          }
          optionClicked(e) {
            e.callback && e.callback(e), this.hideItems();
          }
          hideAnimationDone(e) {
            if ("inactive" === this.fabTogglerState) try {
              document.querySelector(".cdk-overlay-container.rsc-overlay").style.removeProperty("z-index"), this.overlayRef && this.overlayRef.dispose();
            } catch (t) {}
          }
          ngOnDestroy() {
            this.subscription.unsubscribe();
          }
        }
        return e.ɵfac = function (t) {
          return new (t || e)(c.Y36(b.tI), c.Y36(i.aV), c.Y36(i.Xj), c.Y36(h.f), c.Y36(c.sBO));
        }, e.ɵcmp = c.Xpm({
          type: e,
          selectors: [["rsc-speed-dial"]],
          viewQuery: function (e, t) {
            if (1 & e && (c.Gf(y, 7), c.Gf(x, 7)), 2 & e) {
              let e;
              c.iGM(e = c.CRH()) && (t.overlayTemplate = e.first), c.iGM(e = c.CRH()) && (t.overrideIconTemplate = e.first);
            }
          },
          inputs: {
            fabIcon: ["fab-icon", "fabIcon"],
            brandKey: ["brand-key", "brandKey"],
            color: ["mat-color", "color"]
          },
          ngContentSelectors: D,
          decls: 11,
          vars: 10,
          consts: [[1, "rsc-speed-dial"], ["mat-fab", "", 1, "fab-toggler", 3, "color", "click"], [1, "fab-open-icon"], ["overrideIconTemplate", ""], ["class", "fab-open-icon", 4, "ngIf"], [1, "fab-close-icon", "fa-regular", "fa-xmark"], ["class", "fab-secondary", 4, "ngFor", "ngForOf"], ["cdk-portal", ""], ["overlayTemplate", "cdkPortal"], [4, "ngIf", "ngIfElse"], ["fabIconTemplate", ""], ["alt", "fab icon", 3, "src"], [1, "fab-secondary"], [1, "fab-secondary-name"], ["noUrlTemplate", ""], [3, "href", "target", "click"], ["mat-mini-fab", ""], ["mat-mini-fab", "", 3, "click"]],
          template: function (e, t) {
            1 & e && (c.F$t(), c.TgZ(0, "div", 0), c.TgZ(1, "button", 1), c.NdJ("click", function () {
              return t.onToggleFab();
            }), c.TgZ(2, "span", 2, 3), c.Hsn(4), c.qZA(), c.YNc(5, S, 4, 5, "span", 4), c.TgZ(6, "i", 5), c.NdJ("@fabTogglerClose.done", function (e) {
              return t.hideAnimationDone(e);
            }), c.qZA(), c.qZA(), c.TgZ(7, "div"), c.YNc(8, w, 6, 3, "div", 6), c.qZA(), c.qZA(), c.YNc(9, q, 0, 0, "ng-template", 7, 8, c.W1O)), 2 & e && (c.xp6(1), c.Q6J("color", t.color), c.xp6(1), c.Q6J("@fabToggler", c.VKq(6, T, t.fabTogglerState)), c.xp6(3), c.Q6J("ngIf", t.showDefaultIcon), c.xp6(1), c.Q6J("@fabTogglerClose", c.VKq(8, T, t.fabTogglerState)), c.xp6(1), c.Q6J("@speedDialStagger", t.options.length), c.xp6(1), c.Q6J("ngForOf", t.options));
          },
          directives: [v.lW, a.O5, a.sg, n.bH],
          styles: [".rsc-speed-dial{position:fixed;bottom:15px;right:15px;z-index:1001;display:-ms-flexbox;display:flex;-ms-flex-direction:column-reverse;flex-direction:column-reverse;-ms-flex-align:end;align-items:flex-end;color:#fff;font-family:Roboto}.rsc-speed-dial>div{display:-ms-flexbox;display:flex;-ms-flex-direction:column-reverse;flex-direction:column-reverse;-ms-flex-align:end;align-items:flex-end;margin-bottom:5px;margin-right:8px}.rsc-speed-dial>div button{margin-bottom:17px}.rsc-speed-dial .fab-toggler{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;float:right;color:#fff;outline:transparent}.rsc-speed-dial .fab-toggler img{height:24px}.rsc-speed-dial .fab-toggler i{font-size:28px}.rsc-speed-dial .fab-toggler .fab-open-icon>*{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%)}.rsc-speed-dial .fab-secondary button{background-color:#fff!important}.rsc-speed-dial .fab-secondary i{font-size:18px;color:#14171a}.rsc-speed-dial .fab-secondary .fab-secondary-name{margin-right:8px}.rsc-speed-dial .fab-close-icon{display:none;font-size:28px}\n"],
          encapsulation: 2,
          data: {
            animation: u
          }
        }), e;
      })(),
      O = (() => {
        class e {
          constructor(e) {
            if (this.injector = e, !customElements.get(r.VQ.SpeedDial)) {
              const e = (0, s.iD)(A, {
                injector: this.injector
              });
              customElements.define(r.VQ.SpeedDial, e);
            }
          }
        }
        return e.ɵfac = function (t) {
          return new (t || e)(c.LFG(c.zs3));
        }, e.ɵmod = c.oAB({
          type: e
        }), e.ɵinj = c.cJS({
          providers: [{
            provide: i.Xj,
            useClass: d
          }],
          imports: [[a.ez, i.U8, n.eL, l.q]]
        }), e;
      })();
  }
}]);
if (!window.__ImportedRSCComponents__) {
  window.__ImportedRSCComponents__ = [];
}
window.__ImportedRSCComponents__.push('rsc-speed-dial');
window.dispatchEvent(new CustomEvent('RSCElementImported', {
  detail: {
    name: 'rsc-speed-dial'
  }
}));
// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

// Third Party Modules
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

// Internal Component
import { TextboxComponent } from './textbox.component';

const angularModules = [
  CommonModule,
  ReactiveFormsModule,
  MatInputModule,
];
const thirdPartyModules = [NgxMaskDirective];
const externalComponents = [TextboxComponent];

const externalModules = [
  ReactiveFormsModule,
];

@NgModule({
  declarations: [...externalComponents],
  exports: [...externalModules, ...externalComponents],
  imports: [...angularModules, ...thirdPartyModules],
  providers: [provideNgxMask()],
})
export class TextboxModule { }

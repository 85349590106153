import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { DialogsModule } from '../dialogs.module';
import { CardSelectModule } from '../../inputs/card-select/card-select.module';

import { UserSelectDialog } from './user-select.dialog';

const angularModules = [CommonModule, MatButtonModule, MatIconModule];
const lcModules = [DialogsModule, CardSelectModule];
const externalComponents = [UserSelectDialog];

@NgModule({
  declarations: [
    ...externalComponents,
  ],
  exports: [...externalComponents],
  imports: [
    ...angularModules,
    ...lcModules,
  ],
})
export class UserSelectModule { }

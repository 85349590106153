import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'lc-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./form-buttons.component.scss'],
  standalone: false,
})
export class FormButtonsComponent {
  @Input() readonly saveClass: string;
  @Input() readonly cancelClass: string;

  @Input() saveText: string = 'Submit';
  @Input() cancelText: string = 'Cancel';

  @Input() saveDisabled: boolean = false;

  @Input() showSave: boolean = true;
  @Input() showCancel: boolean = true;

  @Output() readonly save = new EventEmitter();
  @Output() readonly cancel = new EventEmitter();
}

// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

// Internal Component
import { FormService } from '@lc/core';
import { TextareaComponent } from './textarea.component';

const angularModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatInputModule,
];

@NgModule({
  declarations: [TextareaComponent],
  exports: [TextareaComponent],
  imports: [...angularModules],
  providers: [FormService],
})
export class TextareaModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from 'ngx-clipboard';

import { UICoreModule } from '@lc/ui';

import { NavigationMenuComponent } from './navigation-menu.component';
import { AddressMenuItemComponent } from './address-menu-item/address-menu-item.component';
import { PackageMenuItemComponent } from './package-menu-item/package-menu-item.component';
import { CompanyMenuItemComponent } from './company-menu-item/company-menu-item.component';
import { AreaMenuItemComponent } from './area-menu-item/area-menu-item.component';

const angularModules = [
  CommonModule,
  RouterModule,
  MatIconModule,
  MatListModule,
  MatButtonModule,
  MatTooltipModule,
  ClipboardModule,
];
const lcModules = [UICoreModule];
const externalComponents = [
  NavigationMenuComponent, AddressMenuItemComponent,
  PackageMenuItemComponent, CompanyMenuItemComponent,
  AreaMenuItemComponent,
];

const externalModules = [MatListModule, MatIconModule];

@NgModule({
  declarations: [
    ...externalComponents,
  ],
  exports: [
    ...externalComponents,
    ...externalModules,
  ],
  imports: [
    ...angularModules,
    ...lcModules,
  ],
})
export class NavigationMenuModule { }

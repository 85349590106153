import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import {
  Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';
import { Subject, merge, of } from 'rxjs';
import { auditTime, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: '[lcResize]',
  templateUrl: './resize.component.html',
  styleUrls: ['./resize.component.scss'],
  standalone: false,
})
export class ResizeComponent implements OnChanges {
  @Input() minHeight: number = 0;
  @Input() minWidth: number = 0;
  @Input() canResize: boolean = true;
  @HostBinding('class') classes: string;
  @Output() readonly resize = new EventEmitter<DOMRect>();

  isDragging: boolean;

  private startSize$ = new Subject<DOMRect>();
  private dragMove$ = new Subject<CdkDragMove>();
  private dragMoveAudited$ = this.dragMove$.pipe(
    withLatestFrom(this.startSize$),
    auditTime(16),
    tap(([{ distance }, rect]) => {
      let smallestDistance = Math.min(distance.x, distance.y);
      const newWidth = rect.width + smallestDistance;
      const newHeight = rect.height + smallestDistance;
      if (newWidth < this.minWidth || newHeight < this.minHeight) {
        const diffX = this.minWidth - newWidth;
        const diffY = this.minHeight - newHeight;
        const greatestDiff = Math.max(diffX, diffY);
        smallestDistance += greatestDiff;
      }

      this.el.nativeElement.style.width = `${rect.width + smallestDistance}px`;
      this.el.nativeElement.style.height = `${rect.height + smallestDistance}px`;
    }),
  );

  sub$ = merge(this.dragMoveAudited$, of(true));

  constructor(private el: ElementRef<HTMLElement>) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.canResize) {
      this.classes = this.canResize ? 'border-1 border-color-primary border-dashed' : '';
    }
  }

  dragStarted(): void {
    this.isDragging = true;
    this.startSize$.next(this.el.nativeElement.getBoundingClientRect());
  }

  dragEnded($event: CdkDragEnd): void {
    this.isDragging = false;
    $event.source._dragRef.reset();
    this.resize.emit(this.el.nativeElement.getBoundingClientRect());
  }

  dragMoved($event: CdkDragMove): void {
    this.dragMove$.next($event);
  }
}

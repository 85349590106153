import { Component, Inject, Optional } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogOptions, PromptDialogService } from '@lc/core';
import { ICardOption } from '../../inputs';

export type UserSelection = 'current-user' | 'obo-user';
export interface IUserSelectDialogData {
  header?: string;
  subheader?: string;
}

@Component({
  selector: 'lc-user-select',
  templateUrl: './user-select.dialog.html',
  styleUrls: ['./user-select.dialog.scss'],
  standalone: false,
})
export class UserSelectDialog {
  /**
   * Opens the form as a dialog
   */
  static async open(service: PromptDialogService, data?: IUserSelectDialogData, options?: IDialogOptions): Promise<UserSelection> {
    return await service.openComponent(UserSelectDialog, data, options);
  }

  readonly value = new FormControl(null, [Validators.required]);
  public readonly options: ICardOption<string>[] = [
    { label: 'Yourself', value: 'current-user' },
    { label: 'Someone Else', value: 'obo-user' },
  ];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) readonly data?: IUserSelectDialogData,
    @Optional() private readonly dialogRef?: MatDialogRef<UserSelectDialog, any>,
  ) { }

  onContinue() {
    if (this.value.invalid) {
      this.value.markAsDirty();
      this.value.markAsTouched();
      return this.value.updateValueAndValidity();
    }
    this.dialogRef?.close(this.value.value);
  }

  onActionClicked(_event) {
    this.dialogRef?.close();
  }
}

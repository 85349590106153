import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IDialogOptions, PromptDialogService } from '@lc/core';

export interface IPdfViewerData {
  url: string;
  title?: string;
}

@Component({
  selector: 'lc-pdf-viewer',
  templateUrl: './pdf-viewer.dialog.html',
  styleUrls: ['./pdf-viewer.dialog.scss'],
  standalone: false,
})
export class PdfViewerDialog {
  readonly safeUrl: SafeResourceUrl;
  /**
   * Opens the form as a dialog
   */
  static async open(service: PromptDialogService, data?: IPdfViewerData, options?: IDialogOptions): Promise<void> {
    const defaultOptions: IDialogOptions = { width: '80vw', height: '90vh', disableClose: false };
    const opts: IDialogOptions = Object.assign(defaultOptions, options);
    return await service.openComponent(PdfViewerDialog, data, opts);
  }

  constructor(
    sanitizer: DomSanitizer,
    @Optional() @Inject(MAT_DIALOG_DATA) readonly data?: IPdfViewerData,
    @Optional() private readonly dialogRef?: MatDialogRef<PdfViewerDialog, any>,
  ) {
    this.safeUrl = data?.url
      ? sanitizer.bypassSecurityTrustResourceUrl(`${data.url}#toolbar=1`)
      : undefined;
  }

  onClose() {
    this.dialogRef?.close();
  }
}

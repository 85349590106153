import {
  Component, EventEmitter, forwardRef, Input, OnInit, OnChanges, Output,
} from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  FormService,
  ProductCode,
  FeatureFlags,
  StringFormControl,
  ProductInstanceForm,
  LaunchDarklyService,
} from '@lc/core';
import { Observable } from 'rxjs';
import { InputField } from '../input-field';
import {
  PropertyDescriptionService,
} from '../../../../../order-management/src/lib/services/property-description.service';
import { Ai } from '../../../../../core/src/lib/models/ai-model';
import { emojiValidator } from '../../../../../core/src/lib/validators/emoji.validator';

/**
 * We expose emit data as ex: {value: formData|event, action: 'cancel'}
 */
export interface EmitData {
  value?: string;
  action: string;
}

/**
 * @deprecated Use lc-text-editor instead
 */
@Component({
  selector: 'lc-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RichTextComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class RichTextComponent extends InputField implements OnChanges, OnInit {
  protected readonly ProductCode = ProductCode;
  @Input() productForm: ProductInstanceForm;
  @Input() productCode: string;
  @Input() label: string;
  @Input() minimumHeight: string;
  @Input() placeholder = '';
  @Input() formControl: FormControl;
  @Input() hint: string;
  @Input() suffix: string;
  @Input() readonly: boolean;
  @Input() richTextStats: any;
  @Input() isHeader: boolean;
  @Input() isContact: boolean;
  @Input() format = 'html';
  @Input() richTextEnabled = true;
  @Input() toolbarEnabled = true;
  @Input() hasConfirmationDialog: boolean;
  @Input() bodyIndex: number;
  @Input() ai: Ai;
  @Input() undoDisabled: boolean = false;

  isRichTextActions: boolean = false;
  textFits: boolean = true;
  printProduct: boolean = false;
  isEmoji: boolean = false;

  @Output()
  readonly change = new EventEmitter<void>();

  @Output() readonly cancel = new EventEmitter();
  @Output() readonly save = new EventEmitter();
  @Output() readonly generate = new EventEmitter();
  @Output() readonly undoAiGeneration = new EventEmitter();

  aiFeatureIsEnabled$: Observable<boolean>;
  initialEditorValue: string = '';
  editorValue: string = '';

  formService: FormService;
  disableSave: boolean;

  constructor(
    sanitizer: DomSanitizer,
    formService: FormService,
    private launchDarklyService: LaunchDarklyService,
    readonly propertyDescriptionService: PropertyDescriptionService,
  ) {
    super(sanitizer);
    this.aiFeatureIsEnabled$ = this.launchDarklyService.getFeature$(FeatureFlags.AI_PROPERTY_DESCRIPTION, false);
    this.formService = formService;
  }

  ngOnChanges(changes) {
    if (changes.richTextStats) {
      const { currentValue: { percentage, maximum, current } } = changes.richTextStats;
      // Tactical number for worst-case scenario. One line of all `i` is about 350 characters,
      // we've added for 2 lines of 'i'
      const buffer = 700;
      const maxAndBuffer = maximum + buffer;
      if (percentage === 100 && current > maxAndBuffer) {
        this.formService.disableFormElements(this.label);
      } else {
        this.formService.enableAllForms();
      }
      this.textFits = (percentage <= 99);
      if (this.printProduct) {
        const hasEmoji = this.formControl.hasError('emojiError');
        this.isEmoji = hasEmoji;
      }
    }
  }

  ngOnInit(): void {
    this.initialEditorValue = this.formControl.value;
    this.isRichTextActionsEnabled();
    this.formService.formErrorDetected.subscribe((subscription) => {
      this.disableSave = subscription.isDisabled;
    }, (error) => {
      throw new Error(`Error from form service in rich text editor: ${error}`);
    });

    const printProducts = ['100', '103', '104', '105', '106', '111', '112'];
    if (printProducts.includes(this.productCode)) {
      this.printProduct = true;
      this.formControl.setValidators([emojiValidator()]);
      this.formControl.updateValueAndValidity();
    }
  }

  onClickedOutside() {
    if (!this.disableSave && !this.hasConfirmationDialog && this.textFits) {
      this.save.emit();
    }
  }

  public onBlur() {
    if (!this.disableSave) {
      if (this.formControl instanceof StringFormControl) {
        this.formControl.onLostFocus();
      }
    }
  }

  public onTextChange(value) {
    this.value = value;
    this.editorValue = value;
  }

  onSave() {
    this.onBlur();
    this.save.emit();
    this.executeOnChanged();
  }

  onCancel() {
    this.formControl.setValue(this.initialEditorValue);
    this.executeOnChanged();
    this.cancel.emit();
  }

  isRichTextActionsEnabled() {
    if (this.productCode && this.productCode !== ProductCode.WEBSITE) {
      this.isRichTextActions = true;
    }
  }

  onGenerate() {
    this.generate.emit();
  }

  onUndo() {
    this.undoAiGeneration.emit();
  }
}

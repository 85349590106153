// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

// Third Party Modules
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';

import { CoreModule } from '@lc/core';
import { UICoreModule } from '../../core';

// Internal Component
import { RichTextComponent } from './rich-text.component';
import { EditorControlComponent } from './editor-control/editor-control.component';
import { TextEditorComponent } from './text-editor/text-editor.component';

const angularModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatButtonModule,
  MatInputModule,
  MatIconModule,
  MatProgressSpinnerModule,
];
const thirdPartyModules = [NgxMaskDirective, QuillModule.forRoot()];
const lcModules = [CoreModule, UICoreModule];

const externalComponents = [RichTextComponent, EditorControlComponent, TextEditorComponent];

@NgModule({
  declarations: [...externalComponents],
  exports: [
    ...externalComponents,
  ],
  imports: [
    ...angularModules,
    ...thirdPartyModules,
    ...lcModules,
  ],
  providers: [provideNgxMask()],
})
export class RichTextModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';

import { MatIconModule } from '@angular/material/icon';

// Internal Components
import { BaseDialog } from './base.dialog';

const angularModules = [CommonModule, MatDialogModule, MatDividerModule, MatButtonModule, MatIconModule];
const externalComponents = [BaseDialog];

@NgModule({
  declarations: [...externalComponents],
  imports: [
    ...angularModules,
  ],
  exports: [...externalComponents],
})
export class BaseDialogModule { }

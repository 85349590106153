import { Deserializable } from './deserializable.model';
import { User } from './user.model';

export class Coupon implements Deserializable<Coupon> {
  discount: number;
  code: string;
  promoCode: string;
  agent: string | User;
  marketingOrder: string; // May also be a marketing order
  amountOff: number;
  percentOff: number;

  constructor(input?: Partial<Coupon>) {
    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): Coupon {
    return Object.assign(this, input);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';

// LC Modules
import { InputsModule } from '../inputs';
import { AgentSearchModule } from '../agent-search';

// Internal Components
import { OrderFiltersComponent } from './components/order-filters/order-filters.component';
import { OrderSearchFiltersComponent } from './components/order-search-filters/order-search-filters.component';

const angularModules = [CommonModule, MatButtonModule];
const lcModules = [InputsModule, AgentSearchModule];
const exportComponents = [OrderFiltersComponent, OrderSearchFiltersComponent];

@NgModule({
  declarations: [...exportComponents],
  imports: [
    ...angularModules,
    ...lcModules,
  ],
  exports: [...exportComponents],
})
export class OrderFiltersModule { }

<div class="alignment fx-column">
    <div style="width: 80%;">
        <h4>Privacy Policy</h4>
        <hr>
    <p class="mat-body-1">Effective Date: 10/1/19</p>
      <p class="mat-body-1">This Privacy Policy (this &ldquo;Policy&rdquo;) describes certain policies of Listing Concierge (collectively, along
          with our affiliates, &ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;us&rdquo;) on how we may collect, process, use,
          store, share, and disclose Personal Information (defined below) provided to us through your use of this website,
          mobile application, or other technology solution (collectively, the &ldquo;Service&rdquo;).</p>
          <p class="mat-headline">WHAT INFORMATION DOES THIS APPLY TO?</p>
        <p class="mat-body-1">This Policy applies to Personal Information collected through the Service. It covers how we may collect, use, store,
          share, disclose, and otherwise process such Personal Information as of the date that this Policy is effective. This
          Policy does not apply to any other information collected by us through any other means, including through third
          parties.</p>
        <p class="mat-body-1">&ldquo;<strong>Personal Information</strong>&rdquo; Personal Information means any information that may directly
          identify you including name, address, mobile or other telephone number, e-mail address, or other similar data elements
          that may be created or made available in connection with using Realogy and/or affiliate services.&nbsp;</p>
        <p class="mat-headline">HOW IS INFORMATION COLLECTED?</p>
        <p class="mat-headline">Personal Information you provide to us when you use the service</p>
        <p class="mat-body-1">We collect the Personal Information you provide directly to us through the Service, including when you create your
          account, provide contact information, and send other Personal Information to us through the Service. We may also
          upload your contact or other Personal Information into the Service if you provide that information to us offline or in
          other ways. Even if you do not send us any Personal Information, we may collect certain non-Personal Information about
          how you use the Service.</p>
        <p class="mat-headline">Personal Information we collect through your use of the Service</p>
        <p class="mat-body-1">When you use the Service, we may collect the following Personal Information about you:</p>


      <ul class="fa-ul">
           <li><i class="fa-li fa fa-circle"></i><em>Real Estate Preferences.</em> We may collect information on your real estate searches and preferences when you
            use the Service, including about whether you plan on buying or selling real estate, home search criteria,
            information about financing for the purchase of real estate, and other information related to the purchase or sale
            of real estate.</li>
           <li><i class="fa-li fa fa-circle"></i><em>Usage Activity and Preferences.</em>We collect information about how you interact with the Service,
            preferences, and settings. In some cases, we do this through the use of cookies and similar technologies such as web
            beacons that create and maintain unique identifiers.</li>
           <li><i class="fa-li fa fa-circle"></i><em>Devices and Software. </em>We may collect information about the device and software you use to access the
            Service, including, for example, hardware, operating system, software and file names and versions, preferred
            language, unique device identifier, advertising identifiers, serial number, and network information.</li>
           <li><i class="fa-li fa fa-circle"></i><em>Log Information. </em>When you interact with the Service, we collect server logs, which may include Personal
            Information including device IP address, access times, app features accessed, pages viewed, app crashes and other
            system activity, type of browser, and referral link information.</li>
           <li><i class="fa-li fa fa-circle"></i>If you permit the Service to access location services used by your device, we may collect the location of your
            device while you are using the Service.</li>
          <li><i class="fa-li fa fa-circle"></i><em>Communication Information.</em> Personal Information sent by you to us through email or other communications
            methods, including messaging functionalities of the Service.</li>
        </ul>
       <p class="mat-body-1">To learn more about your ability to control the collection of the information above regarding your preferences, usage
          activity, device, software, log and location information through tracking and similar technologies, please see the
          sections on &ldquo;Cookies&rdquo; and &ldquo;What's the Policy on Third Party Analytics and Advertising
          Services&ldquo; below.</p>

        <p class="mat-headline"><em>Other Personal Information</em></p>
        <p class="mat-body-1">We may also receive Personal Information from other sources, including:</p>
        <ul class="fa-ul">
           <li><i class="fa-li fa fa-circle"></i><em>Social Media.</em> If you choose to link, create, or log in to your Service account with a social media
            service (e.g., Twitter, Facebook, Instagram, etc.), or if you engage with a separate app or website that
            communicates with the Service, we may receive Personal Information about you or your connections from that service.
            In many cases you can control what Personal Information you share with us through privacy settings available on
            those third-party social media services.</li>
           <li><i class="fa-li fa fa-circle"></i><em>Third Parties.</em> If you interact with us in a capacity outside of the Service, including on any other
            platform or in person, we may combine or associate that information with Personal Information we have collected from
            you through the Service, as well as with pre-existing Personal Information or Personal Information we have collected
            from third party offline or online sources.</li>
           <li><i class="fa-li fa fa-circle"></i><em>Pre-Populated Personal Information. </em>Pre-populated Personal Information is information that may include
            your name and email address, that you may have given to a real estate professional with whom you have a pre-existing
            relationship. The professional may collect such pre-populated Personal Information from you with the sole purpose of
            pre-populating an account for the Service on your behalf and with your consent. You may choose not to finalize the
            creation of an account and may opt-out of all future communications from us by following the instructions in the
            account creation notification or by contacting us at the email address at the bottom of this page.</li>
        </ul>
        <p class="mat-headline">HOW IS PERSONAL INFORMATION USED?</p>
        <p class="mat-body-1">We may use the Personal Information collected as described above as follows:</p>
       <ul class="fa-ul">
           <li><i class="fa-li fa fa-circle"></i><em>Provide the Service. </em>Provide, maintain, and improve the Service, including, for example, to send or
            facilitate communications among you, independent real estate professionals and their affiliated entities, and other
            users of the Service, provide products and services you request (and send related information), develop new
            features, provide customer support, and send updates and administrative messages.</li>
           <li><i class="fa-li fa fa-circle"></i>Perform internal operations, including, for example, to troubleshoot bugs and operational issues, improve
            security; conduct data analysis, testing, and research; and monitor and analyze usage and activity.</li>
           <li><i class="fa-li fa fa-circle"></i>Inform you about other services and products that may be available through us, our family of companies, our
            franchisees and/or companies with which we have a marketing relationship, and to provide you a customer survey to
            assess your satisfaction with our services and products.</li>
           <li><i class="fa-li fa fa-circle"></i>Personalize and improve the Service, including to provide or recommend features, content, social connections,
            referrals, and advertisements.</li>
        </ul>
        <p class="mat-headline">LOCATION OF SERVERS AND TRANSFER OF INFORMATION TO THE UNITED STATES</p>
        <p>The Service is hosted on servers located in the United States. Note that some countries may not provide the same
          level of data protection as your home country. If you are located, for example, in European Economic Area or
          Switzerland (collectively, &ldquo;<strong>Europe</strong>&rdquo;), your Personal Information will be transferred to
          the United States and possibly to other jurisdictions (e.g., to the location of a property for which you have
          requested information), which may not provide the same level of protection under your local data protection laws. In
          such cases, we rely on suitable Personal Information transfer safeguards. You may have the right to request a copy or
          information about those safeguards by contacting us as detailed below.</p>
        <p class="mat-headline">HOW IS INFORMATION SHARED?</p>
        <p>We may share the Personal Information we collect about you as described in this Policy or as otherwise described at
          the time of collection or sharing, including as follows:</p>
        <p class="mat-headline"><em>Through the Service.</em></p>
        <p>We may share your Personal Information:</p>
       <ul class="fa-ul">
           <li><i class="fa-li fa fa-circle"></i>With independent real estate professionals and owners and operators of other franchised real estate brokerage
            companies to enable them to provide services you request through the Service.</li>
           <li><i class="fa-li fa fa-circle"></i>With third parties to provide you a service you requested through a partnership or promotional offering made by a
            third party or us.</li>
           <li><i class="fa-li fa fa-circle"></i>With the public if you submit to a public part of the Service, such as comments, social media posts, or other
            features viewable by the public or generally by registered users of the Service.</li>
           <li><i class="fa-li fa fa-circle"></i>With third parties with whom you choose to let us share information, for example other apps or websites that
            integrate with the Service, or those with a Service with which we integrate.</li>
        </ul>
        <p class="mat-headline"><em>Through other means of Sharing.</em></p>
        <p>We may share your Personal Information:</p>
       <ul class="fa-ul">
           <li><i class="fa-li fa fa-circle"></i>With our affiliates.</li>
           <li><i class="fa-li fa fa-circle"></i>With third party or affiliate service providers that provide services or conduct data processing on our behalf, or
            for data centralization and/or logistics purposes.</li>
           <li><i class="fa-li fa fa-circle"></i>To enable our advertisers to provide you with more personalized content and track the effectiveness of campaigns.
          </li>
           <li><i class="fa-li fa fa-circle"></i>With vendors, consultants, marketing partners, and other service providers who need access to such information to
            carry out work on our behalf.</li>
           <li><i class="fa-li fa fa-circle"></i>In response to a request for information by a competent authority if we believe disclosure is in accordance with,
            or is otherwise required by, any applicable law, regulation, or legal process.</li>
           <li><i class="fa-li fa fa-circle"></i>In connection with the potential merger, sale of company assets, consolidation or restructuring, financing, or
            acquisition of all or a portion of our business by or into another company.</li>
           <li><i class="fa-li fa fa-circle"></i>In an aggregated, de-identified, or anonymous form which cannot reasonably be used to identify you.</li>
        </ul>
        <p class="mat-headline">Legal grounds for the collection, use, sharing, and other processing of Personal Information</p>
        <p>Certain jurisdictions, including those in Europe, require the identification of the legal grounds for the collection,
          use, sharing, and other processing of Personal Information. We rely on the following legal grounds for the collection,
          use, sharing, and other processing of Personal Information as described in this Policy:</p>
       <ul class="fa-ul">
           <li><i class="fa-li fa fa-circle"></i>Necessary to provide information or otherwise carry out the performance of a contract with you as an individual;
          </li>
           <li><i class="fa-li fa fa-circle"></i>Our legitimate interests, including:</li>
        </ul>
       <ul class="fa-ul">
         <ul class="fa-ul">
             <li><i class="fa-li fa fa-circle fa-font-weight"></i>Performance of contracts with our franchisees;</li>
             <li><i class="fa-li fa fa-circle fa-font-weight"></i>Customer relationship management, including other forms of marketing and analytics;</li>
             <li><i class="fa-li fa fa-circle fa-font-weight"></i>Implementation and operation of a group-wide matrix structure and group-wide information sharing;</li>
             <li><i class="fa-li fa fa-circle fa-font-weight"></i>Fraud prevention, misuse of company IT systems, or money laundering;</li>
             <li><i class="fa-li fa fa-circle fa-font-weight"></i>Physical, IT, and network perimeter security;</li>
             <li><i class="fa-li fa fa-circle fa-font-weight"></i>Internal investigations; and</li>
             <li><i class="fa-li fa fa-circle fa-font-weight"></i>Intended mergers and acquisitions.</li>
          </ul>
        </ul>
       <ul class="fa-ul">
           <li><i class="fa-li fa fa-circle"></i>Compliance with legal obligations and/or defense against legal claims, including those in the area of labor and
            employment law, social security, and data protection, tax, and corporate compliance laws.</li>
           <li><i class="fa-li fa fa-circle"></i>Protection of the vital interests of any individual;</li>
           <li><i class="fa-li fa fa-circle"></i>Performance of a task carried out in the public interest or in the exercise of official authority vested in us;
            and</li>
           <li><i class="fa-li fa fa-circle"></i>Consent, as permitted by applicable law.</li>
        </ul>
        <p class="mat-headline">HOW DOES SOCIAL SHARING APPLY?</p>
        <p>The Service may integrate with social sharing features and other related tools which let you log in to the Service or
          share actions you take on the Service. Your use of such features enables the sharing of information with your friends
          or the public, depending on the settings you establish with the social sharing service. Please refer to the privacy
          policies of those social sharing services for more information about how they handle the data you provide to or share
          through them. We do not receive or store passwords to your third-party social media accounts in this context.</p>
        <p class="mat-headline">SECURITY</p>
        <p>We use technical, administrative, and physical controls in place to protect your Personal Information from
          unauthorized access, use, and disclosure. For example, we provide the option to encrypt information you submit to us
          using Secure Sockets Layer technology that helps protect information during transport to our website. We periodically
          review our security measures to evaluate industry best practices and update accordingly. Even so, despite our
          reasonable efforts, no security measure is ever perfect or impenetrable.</p>
        <p class="mat-headline">DATA RETENTION</p>
        <p>We will retain your Personal Information only for the period necessary to fulfill the purposes outlined in this
          Policy or as set out in our contracts with our franchisees, unless a longer retention period is required for our
          legitimate business purposes or permitted by a law that applies to us.</p>
        <p>Realogy retains your Personal Information only to the extent necessary to perform contractual obligations and to meet
          the compliance obligations that apply to our international business, as reflected in our record retention
          policy.&nbsp; When we no longer need your Personal Information, we will securely remove, deactivate, or de-identify
          your Personal Information from our systems. If you wish to request that Realogy and/or it&rsquo;s affiliates no longer
          use your Personal Information for the agreed upon services, please contact us at dataprivacy&#64;realogy.com We will
          retain and use your information as necessary to comply with our legal obligations, resolve disputes and enforce our
          agreements.</p>
        <p class="mat-headline">what&rsquo;s the policy on third party ANALYTICS AND ADVERTISING SERVICES?</p>
        <p>We may allow others to provide audience measurement and analytics services for us, to serve advertisements on our
          behalf, and to track and report on the performance of those advertisements. These entities may use cookies, web
          beacons, SDKs, and other technologies to identify your device when you use the Service, and such services will be
          covered by the privacy policy of the respective third parties.</p>
        <p>We use Google Analytics to collect information about use of the Service. More information on Google Analytics &ndash;
          including how you can control the information sent to Google - can be found&nbsp;here: <a
            href="http://www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/</a>.</p>
        <p class="mat-headline">WHAT&rsquo;S YOUR COOKIE POLICY?</p>
        <p class="mat-headline"><em>What are cookies?&nbsp;</em><em></em></p>
        <p>Cookies are small data files stored in your device memory when you visit a website. Cookies and similar technologies
          are widely used by websites to make them work more efficiently, as well as to provide information to the website
          operator about how users are using their website.&nbsp;</p>
        <p class="mat-headline"><em>How does the Service use cookies?</em></p>
        <p>We use cookies to improve the Service and your experience, for example to enable you to move easily between the pages
          of the Service websites. We also use cookies for analytics purposes, to see which areas and features of the Service
          are popular, and to count visits. Additionally, we may use 'web beacons', which are electronic images that may be used
          on the Service, including in our emails to help deliver cookies, count visits, and understand usage and campaign
          effectiveness.</p>
        <p><em>Essential cookies</em>&nbsp;are essential, as they enable you to move around the Service and use its features.
          Without these cookies we could not, for example, authenticate your login or remember that you are logged-in as you
          move through the Service.</p>
        <p><em>Functionality cookies</em>&nbsp;allow us to remember choices you make (such as the region you're in) and tailor
          the Service to provide enhanced features and content for you. For instance, they can be used to remember your login
          details or remember your nickname when you next login. They may also be used to provide services you've requested, for
          example leaving a comment on the Service.</p>
        <p><em>Analytics &amp; performances cookies</em>&nbsp;collect information about how the Service is used, for example
          which pages users visit most often and if any error messages are displayed on certain pages. Information collected
          using these cookies is used to monitor activity on the Service and understand how it is being used.</p>
        <p class="mat-headline"><em>How to change your cookie settings.</em></p>
        <p>Most devices allow you to block cookies by adjusting the settings on your browser. However, if you use your browser
          settings to block cookies (including essential cookies, such as those allowing you to log-in) you may not be able to
          access all or parts of the Service.&nbsp;</p>
        <p>To find out more about cookies, including how to see what cookies have been set and how to manage and delete them,
          please refer to your browser 'help' section, or
          visit&nbsp;www.aboutcookies.org&nbsp;or&nbsp;www.allaboutcookies.org.&nbsp;</p>
        <p class="mat-headline"><em>Cookies that have been set in the past.</em></p>
        <p>If you have disabled one or more cookies, we may still use information collected from cookies before your disabled
          preference was set. However, we will stop using the disabled cookie to collect any further information.</p>
        <p class="mat-headline"><em>Controlling website tracking tools.</em></p>
        <p>Your browser may give you the ability to control cookies. How you do so, however, depends on your browser and the
          type of cookie. Certain browsers can be set to reject all browser cookies. If you configure your computer to block all
          cookies, you may disrupt certain web page features, and limit the functionality we can provide when you visit or use
          the Service. (e.g., we will not be able to provide you with searches that you have asked us to save). You can change
          your cookie settings at any time. Some browsers have a &ldquo;<strong>Do Not Track</strong>&rdquo; feature that lets
          you tell websites that you do not want to have your online activities tracked. These browser features are still not
          uniform, so we are not currently set up to respond to those signals.</p>
        <p class="mat-headline">Your rights AND CHOICES </p>
        <p>You are not required to provide all Personal Information identified in this Privacy Statement to use the Service, but
          certain aspects of the Service will not be available to you. However, you can use our services without consenting to
          non-essential cookies or direct marketing emails; the only consequence is that our products and services will be less
          communicated, we may not be able to provide the support you seek, and you may miss out on attractive promotions and
          news about our products and services. Note that in certain cases, your Personal Information will be necessary for us,
          or an affiliated third party, to comply with legal obligations, or enter into and perform a contract with you.</p>
        <p>If you reside in Europe, local data protection law may afford you the right to: obtain confirmation of the existence
          of certain information relating to you, to verify its content, origin, and accuracy, as well as the right to access,
          review, obtain a copy, port, delete, or to block or withdraw consent to the processing of certain information (without
          affecting the lawfulness of processing based on consent before its withdrawal), by contacting us as detailed below.
          You may also have the right to file a complaint with an applicable supervisory authority regarding our processing of
          your Personal Information.</p>
        <p class="mat-headline"><em>Deactivation.</em></p>
        <p>You can deactivate your account at any time by contacting us at the email address at the bottom of this page.
          However, you will not be able to access many of the services to deactivate your account. Please note that even if you
          request deactivation of your account, it may take some time to fulfill this request.</p>
        <p class="mat-headline"><em>Promotional communications.</em></p>
        <p>You may opt out of receiving promotional messages from us by following the instructions in those messages. If you opt
          out, we may still send you non-promotional communications, such as those about your account, about services you have
          requested, and our ongoing business relationship.</p>
        <p class="mat-headline"><em>Cookies and similar technologies. </em></p>
        <p>Please review your browser or device settings for certain cookies and see above to exercise certain choices regarding
          cookies.</p>
        <p class="mat-headline">Non-US Privacy Rights</p>
        <p>Europe and certain other non-US jurisdictions maintain local data protection regulations that confer certain data
          protection rights on individuals. We will address those rights where required by applicable laws. Note that if you are
          a franchisee or an employee of a franchisee, and we obtain access or otherwise process your Personal Information in
          that context, you should contact your employer/franchisee which will be in the best position to respond to your
          inquiry. If applicable, and you wish to exercise any of these rights, please contact us as set out below.</p>

        <ol class="fa-ul ol-list">
           <li><strong>Right of access: </strong>You have the right to obtain from us confirmation as to whether Personal
            Information concerning you is processed, and, to request access to the Personal Information. The access information
            includes, among other things, the purposes of the processing, the categories of Personal Information concerned, and
            the recipients or categories of recipient to whom the Personal Information have been or will be disclosed. This is
            not, however, an absolute right, and the interests of other individuals may restrict your right of access. You may
            have the right to obtain a copy of their Personal Information undergoing processing.</li>
           <li><strong>Right to rectification: </strong>You have the right to obtain from us the rectification of inaccurate
            Personal Information about you. Depending on the purposes of the processing, you may have the right to have
            incomplete Personal Information completed, including by means of providing a supplementary statement.</li>
           <li><strong>Right to erasure (right to be forgotten): </strong>Under certain circumstances, you have the right to
            obtain from us the erasure of Personal Information concerning you, and we may be obligated to erase that Personal
            Information.</li>
           <li><strong>Right to restriction of processing:</strong> Under certain circumstances, you have the right to obtain
            from us restriction of processing your Personal Information. In that case, your data will be marked and may only be
            processed by us for certain limited purposes. As we process and use your Personal Information primarily for purposes
            of carrying out the contract for a services relationship with your employer, we will have a legitimate interest for
            the processing which will override your restriction request, unless the restriction request relates to marketing
            activities.</li>
           <li><strong>Right to data portability: </strong>Under certain circumstances, you have the right to receive the
            Personal Information about you, which you have provided to us, in a structured, commonly used and machine-readable
            format, and you have the right to transmit that data to another entity without hindrance from us.</li>
           <li><strong>Right to object: </strong>Under certain circumstances, you have the right to object, on grounds relating
            to your situation, at any time to the processing of your Personal Information by us, and we can be required to no
            longer process your Personal Information.</li>
        </ol>
        <p class="mat-body-1">Where permitted by applicable law, you also have the right to lodge a complaint with a competent data protection
          supervisory authority.</p>
        <p class="mat-headline">what are california privacy rights?</p>
        <p class="mat-body-1">California residents may request from businesses with whom they have an established business relationship (1) a list
          of categories of Personal Information, such as name, address, e-mail address, and the type of services provided to the
          customer, that a business has disclosed to third parties during the immediately preceding calendar year for the third
          parties' direct marketing purposes and (2) the names and addresses of all such third parties. To request the above
          information please contact us by writing to us under the &ldquo;Contact Us&rdquo; section below. We will respond to
          such requests within 30 days of receipt.</p>
        <p>We may change this Policy and will provide notice of changes through the Service or by email. Your continued use of
          the Service after notice constitutes your consent to the change.</p>
        <p class="mat-headline">CHILDREN</p>
        <p class="mat-body-1">The Service is not intended or directed to children under the age of 16, and we do not knowingly collect any Personal
          Information, or knowingly track the use of our Service, from children. If we have actual knowledge that Personal
          Information about a child under 16 years old has been collected, then we will take appropriate steps to try and delete
          such Personal Information.</p>
        <p class="mat-headline">THIRD PARTY WEBSITES</p>
        <p class="mat-body-1">The Service may contain links to third-party services. We do not own, operate, or control the websites of
          unaffiliated third parties. Accordingly, this Policy does not apply to any services maintained or operated by
          unaffiliated third-parties. When you click on those links, you will go to a third-party website where you will be
          subject to that service&rsquo;s privacy policy or similar statement and terms of use, and we encourage you to read
          that policy statement. We are not responsible for the privacy practices of other services, and we expressly disclaim
          any liability for their actions, including actions related to the use and disclosure of Personal Information by those
          third parties.</p>
        <p class="mat-headline">CHANGES TO THIS POLICY; LANGUAGE</p>
        <p class="mat-body-1">We may amend this Policy at any time. If we make any material change in how we collect, use, disclose, or otherwise
          process Personal Information, we will prominently post a notice regarding such change on the Service. Any material
          changes to this Policy will be effective 10 days after our posting of the updated Policy or as otherwise required by
          applicable law. Where required to do so by law, we may seek your prior consent to any material changes we make to this
          Policy. This Policy is provided in English only to avoid any misinterpretations caused by translation. If necessary,
          please seek appropriate advice before proceeding or do not use the Service.</p>
        <p class="mat-headline">CONTACT US</p>
        <p class="mat-body-1">If you have any questions or concerns about this Policy, please contact us.</p>
        <p class="mat-body-1">Email: dataprivacy&#64;realogy.com</p>
        <p class="mat-body-1">Mailing address: 175 Park Ave.,&nbsp;Madison, NJ 07940 Attn: Data Privacy</p>
        <p class="mat-body-1">Phone number: (973) 407-2000</p>

    </div>
</div>


import { Deserializable } from './deserializable.model';
import { PhotograperDetails } from './photographer-details.model';
import { PhoneNumber } from './phone-number.model';
import { VendorOverrides } from './vendor-overrides.model';

export class PhotoAgency implements Deserializable<PhotoAgency> {
  _id: string;
  code: number;
  title: string;
  agencyEmail: string;
  agencyPhoneNumber: PhoneNumber;
  photographerDetails: PhotograperDetails[];
  thumbnailUri: string;
  coverageZipCodes: string;
  productCodeServiced: string = '101';
  description: string;
  useCustomScheduling: boolean;
  customSchedulingUrl?: string;
  vendorOverrides?: VendorOverrides;
  supportedBrandCodes: string[];

  constructor(input?: any) {
    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): PhotoAgency {
    Object.assign(this, input);
    this.agencyPhoneNumber = new PhoneNumber(this.agencyPhoneNumber);
    this.photographerDetails = (this.photographerDetails || []).map((detail) => new PhotograperDetails(detail));

    if (this.vendorOverrides) {
      this.vendorOverrides = new VendorOverrides(this.vendorOverrides);
    }
    return this;
  }
}

import {
  Component, Input, OnChanges, SimpleChanges,
} from '@angular/core';
import { MarketingOrderQRCodes, QRCodeService } from '@lc/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'lc-qr-statistics-table',
  templateUrl: './qr-statistics-table.component.html',
  styleUrls: ['./qr-statistics-table.component.scss'],
  standalone: false,
})
export class QRStatisticsTableComponent implements OnChanges {
  readonly displayedColumns = ['productTitle', 'numberOfScans'];
  data$: Observable<MarketingOrderQRCodes>;

  @Input() marketingOrderId: string;

  constructor(private qrCodeService: QRCodeService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.marketingOrderId) {
      this.getQRStats(this.marketingOrderId);
    }
  }

  async getQRStats(marketingOrderId: string) {
    if (marketingOrderId) {
      this.data$ = this.qrCodeService.getQRStats(marketingOrderId);
    }
  }
}

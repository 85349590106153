import momentTimeZone from 'moment-timezone';
import { TimezoneDisplays } from '../models/timezone.type';

export type NgbDate = { year: number; month: number; day: number; };
export type NgbTime = { hour: number; minute: number; };

export class TimeValue {
  /**
   * Take a timezone offset and make it into a number of hours and minutes
   *
   * Note:
   *
   * This is a naive implementation and only works for timezone offsets
   * which offset a whole number of hours. There are only a few time zones which
   * offset a number of hours and 30 minutes and Realogy is not currently
   * doing business in these timezones.
   *
   * See here: https://en.wikipedia.org/wiki/Time_zone
   *
   * @param timezoneOffset
   */
  static fromTimezoneOffset(timezoneOffset: string): TimeValue {
    const offset = Number(timezoneOffset);
    const hourOffset = offset / 100;

    return new TimeValue(hourOffset, 0);
  }
  constructor(public hour: number, public minute: number) {
  }

  /**
   * Add the hours and minutes from another TimeValue.
   *
   * @param tv
   */
  add(tv: TimeValue) {
    return new TimeValue((this.hour + tv.hour) % 24, (this.minute + tv.minute) % 60);
  }

  /**
   * convert the timevalue to a new time-value byu applying the offset.
   *
   * @param timeZoneOffset
   */
  addOffset(timeZoneOffset: string) {
    if (timeZoneOffset) {
      return this.add(TimeValue.fromTimezoneOffset(timeZoneOffset));
    }
    return this;
  }
}

export class DateHelper {
  // 8am is the default appointment time if none already selected.
  static defaultTime = new TimeValue(8, 0);

  /**
   * convert the date of the appointment to a time-value (hour and minute)
   * @param scheduledDate
   * @private
   */
  static getTimePicker(scheduledDate?: Date, timezoneOffset?: string) {
    if (scheduledDate == null) {
      return DateHelper.defaultTime;
    }
    const date = new Date(scheduledDate);
    // The data is in UTC but we have to get a timevalue for the timezone the user selected,
    // we do this by applying the offset in hours and minuts to the timevalue.
    // we are assuming that all offsets are in 1 hour increments
    return new TimeValue(date.getUTCHours(), date.getUTCMinutes()).addOffset(timezoneOffset);
  }

  static getUTCInfo(date: Date, timezone: string) {
    const dateFormatMoment = 'YYYY-MM-DDTHH:mm:ss.SSS';
    const getFormattedDate = momentTimeZone(date).utc().format(dateFormatMoment);
    const getTimeZoneProp = TimezoneDisplays.filter((display) => display.value === timezone);
    const getTimeZoneNameProp = getTimeZoneProp[0];
    const dateConversionStartDateUTC = getTimeZoneNameProp ? momentTimeZone
      .tz(getFormattedDate, getTimeZoneNameProp.timezoneName)
      .utc().toISOString() : ''; // Convert to UTC
    const getOffset = getTimeZoneNameProp ? momentTimeZone(dateConversionStartDateUTC).tz(getTimeZoneNameProp.timezoneName).format('ZZ') : '';

    return {
      dateAsUTC: dateConversionStartDateUTC,
      timezoneOffset: getOffset,
    };
  }

  /** Converts the NgbDate back to a Date object */
  static ngbToDate(date: NgbDate, time: NgbTime) {
    return new Date(
      Date.UTC(
        date.year,
        date.month - 1, // convert back from ISO 8601 format before saving
        date.day,
        time.hour,
        time.minute,
      ),
    );
  }

  /** Converts the time to the NgbFormat to be used in the Ui */
  static dateToNgbTime(date: Date) {
    if (date == null) { return { hour: 8, minute: 0 }; }
    const ngbDate = new Date(date);
    return { hour: ngbDate.getHours(), minute: ngbDate.getMinutes() };
  }

  /** Converts the date to the NgbFormat to be used in the UI */
  static dateToNgbDate(date: Date) {
    if (date == null) { return null; }
    const ngbDate = new Date(date);
    return {
      year: ngbDate.getFullYear(),
      month: ngbDate.getMonth() + 1, // convert to ISO 8601 format for NgbDate (Jan=1, Feb=2,...)
      day: ngbDate.getDate(),
    };
  }

  static dateToNgxDate(date: Date) {
    if (date == null) { return null; }
    const ngxDate = new Date(date);
    return {
      year: ngxDate.getFullYear(),
      month: ngxDate.getMonth() + 1, // convert to ISO 8601 format for NgbDate (Jan=1, Feb=2,...)
      day: ngxDate.getDate(),
    };
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { FormattingService } from '../services';

@Pipe(
  {
    name: 'cssSafe',
    standalone: false,
  },
)

/**
 * CssSafePipe wil do its best to ensure that a string is converted to a form
 * suitable for use in CSS class names.
 *
 * @see FormattingService
 */
export class CssSafePipe implements PipeTransform {
  constructor(private formattingService: FormattingService) {

  }

  transform(value: any, args?: any): string {
    return this.formattingService.formatDisplayTextAsCssClass(value);
  }
}

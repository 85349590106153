// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { DialogsModule } from '../../dialogs/dialogs.module';

// Internal Component
import { CardSelectComponent } from './card-select.component';
import { CardSlideAnimationDirective } from './card-slide-animation.directive';

const angularModules = [
  CommonModule, ReactiveFormsModule,
  MatInputModule, MatTooltipModule, MatIconModule, MatButtonModule,
];
const lcModules = [DialogsModule];

@NgModule({
  declarations: [
    CardSelectComponent,
    CardSlideAnimationDirective,
  ],
  exports: [
    CardSelectComponent,
    ReactiveFormsModule,
  ],
  imports: [
    ...angularModules,
    ...lcModules,
  ],
})
export class CardSelectModule { }

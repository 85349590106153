import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Masks } from '../input-masks';
import { TextboxComponent } from '../textbox/textbox.component';

@Component({
  selector: 'lc-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneNumberComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class PhoneNumberComponent extends TextboxComponent {
  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
    this.inputMask = Masks.phoneNumber;
    this.placeholder = '000.000.0000';
  }
}
